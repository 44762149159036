import React from "react";
import {FormattedMessage} from "react-intl";
import {Modal, Typography} from "antd";
import moment from "moment-timezone";


function PartnerResponsePopUp({visible,  partnerResponse, handleCancel}) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
        <Modal
            className="partnerResponse-popup "
            title={<FormattedMessage id="show_details" />}
            visible={visible}
            width={424}
            centered
            onCancel={handleCancel}
            footer={null}
        >
            <div className="partnerResponse-popup--list">
                <div className="title"><FormattedMessage id="id" /> :</div>
                <div className="description">{partnerResponse.id}</div>
            </div>
            <div className="partnerResponse-popup--list">
                <div className="title"><FormattedMessage id="date" /> :</div>
                <div className="description withSpacing">{moment(partnerResponse.created).tz(timeZone).format("DD/MM/YYYY   HH:mm:ss")}</div>
            </div>
            <div className="partnerResponse-popup--list">
                <div className="title"><FormattedMessage id="response_date" /> :</div>
                <div className="description withSpacing">{partnerResponse.responseDate ? moment(partnerResponse.responseDate).tz(timeZone).format("DD/MM/YYYY   HH:mm:ss") : ""}</div>
            </div>
            <div className="partnerResponse-popup--list message">
                <div className="title"><FormattedMessage id="message" /> :</div>
            </div>
            <Typography.Paragraph>
                <pre>{JSON.stringify(partnerResponse.partnerResponse," ", 2)}</pre>
            </Typography.Paragraph>
            <div className="MainModal--footer text-right">
                <button type="reset" className="miniBtn default" onClick={handleCancel}>
                    <FormattedMessage id='cancel'/>
                </button>
            </div>
        </Modal>
    )
}


export default PartnerResponsePopUp;