import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getFormattedUrlObject, getUrlString, getUrlValue} from "../../../utils/CommonUtils";
import {Table} from "antd";
import PlayersByIp from "@components/PlayersByIp";
import {getActiveBetList, clearActiveBetList} from "@actions/activeBetsAction";
import {GamePrediction} from "@constants/Enums";
import {timestampToParts} from "@services/TradingRatesService";
import classNames from "classnames";
import predictionAll from "@assets/img/icon/all.svg";
import predictionUp from "@assets/img/icon/up.svg";
import predictionDown from "@assets/img/icon/down.svg";
import PlayersListPopup from "@components/PlayersListPopup";
import ActiveBetsFilter from "@components/ActiveBetsFilter/ActiveBetsFilter";
import TableLoader from "@components/Loaders/tableLoader";

const predictionIcons = [predictionAll, predictionUp, predictionDown]

function ActiveBets({intl, loading, getActiveBetList, clearActiveBetList, activeBets}) {
    let history = useHistory()
    const [showFilter, setShowFilter] = useState(false);
    const [online, setOnline] = useState(navigator.onLine);
    const [selectedPlayer, setSelectedPlayer] = useState("");
    const timeOut = useRef([])
    
    useEffect(() => {
        function enable(e) {
            setOnline(true)
        }
        function disable(e) {
            setOnline(false)
        }
        window.addEventListener('online', enable);
        window.addEventListener('offline', disable);

        return () => {
            window.removeEventListener('online', enable);
            window.removeEventListener('offline', disable);
            clearActiveBetList();
        }
    },[])

    useEffect(() => {
        if (online) {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
            fetchBets()
        }
    },[online])

    const columns = [
        {
            title: intl.formatMessage({id: "client"}),
            dataIndex: 'clientName',
            width: "fit-content",
            render: (text, record) => {
                return <div>{record.clientName}</div>
            }
        },
        {
            title: intl.formatMessage({id: "player"}),
            dataIndex: 'playerName',
            width: "fit-content",
            render: (text, record) => {
                return <div>{record.playerName}</div>
            }
        },
        {
            title: intl.formatMessage({id: "amount"}),
            dataIndex: 'amount',
            width: "fit-content",
            render: (text, record) => {
                return <div>{record.amount}</div>
            }
        },
        {
            title: intl.formatMessage({id: "asset"}),
            dataIndex: 'asset',
            width: "fit-content",
            render: (text, record) => {
                return <div>{record.asset}</div>
            }
        },
        {
            title: intl.formatMessage({id: "prediction"}),
            dataIndex: 'prediction',
            width: "fit-content",
            render: (text, record) => {
                return <div className="prediction">
                    <img src={predictionIcons[record.prediction]} alt=""/>
                    <FormattedMessage id={GamePrediction[record.prediction]}/>
                </div>
            }

        },
        {
            title: intl.formatMessage({id: "time"}),
            dataIndex: 'time',
            width: "fit-content",
            render: (text, record) => {
                return <div>{timestampToParts(record.duration || record.time, intl)}</div>
            }

        },
        {
            title: intl.formatMessage({id: "rate"}),
            dataIndex: 'rate',
            width: "fit-content",
            render: (text, record) => {
                return <div>{record.rate}</div>
            }
        },
        {
            title: intl.formatMessage({id: "payout"}),
            dataIndex: 'payout',
            width: "fit-content",
            render: (text, record) => {
                return <div>{record.payout}</div>
            }
        },
        {
            title: intl.formatMessage({id: "ip"}),
            dataIndex: 'ip',
            width: "fit-content",
            render: (text, record) => {
                return  <PlayersByIp label={record.ip} />
            }
        }
    ];

    const getBetsRequestBody = (loc) => {
        let search = history && history.location && history.location.search
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj)
        return {
            ...initialForm,
            predictions: initialForm.predictions ? initialForm.predictions.toString() : "",
            assets: initialForm.assets ? initialForm.assets.toString() : "",
            playerNameLike: selectedPlayer ? selectedPlayer : (initialForm.playerNameLike || ""),
        }
    }

    const fetchBets = () => {
        let initialForm = getBetsRequestBody()
        initialForm = {
            ...initialForm,
            playerNameLike: selectedPlayer ? selectedPlayer : (initialForm.playerNameLike || "")
        }
        getActiveBetList(initialForm)
    }

    useEffect(() => {
        if (!loading._active_bet_list && online) {
            timeOut.current.push(setInterval(() => {fetchBets()}, 5000))
        }
        return () => {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
        }
    }, [loading._active_bet_list])

    useEffect(() => {
        return history.listen((location, action) => {
                const loc = location.search
            if (loc) {
                const initialForm = getBetsRequestBody(loc)
                getActiveBetList(initialForm)
                timeOut.current.forEach(time => clearInterval(time))
                timeOut.current = []
            }

        })
    },[history])

    useEffect(() => {
        if (selectedPlayer) {
            const initialForm = getBetsRequestBody()
            const {params} = getUrlString({
                clientId: initialForm.clientId,
                playerNameLike: selectedPlayer
            })

            history.push({search: params.toString()})
            setSelectedPlayer("")
        }

    }, [selectedPlayer])





    return (
        <div className="content">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='active_bets'/></div>
                <button className={classNames("filterBtn", {show: showFilter})} onClick={() => setShowFilter(prev => !prev)}>
                    <div className="icon"/>
                    <FormattedMessage id='filter'/>
                </button>
            </div>
            <ActiveBetsFilter showFilter={showFilter}/>
            <div className="activeBets">
                <div className="mainTable">
                    {loading._active_bet_list && !activeBets.items ? <TableLoader count={10} column={8} icon={false} drag={false} actions={0} pagination={false}/> :
                        <div>
                            <Table dataSource={activeBets.items || []}
                                   columns={columns}
                                   rowKey="id"
                                   scroll={{x: '100%'}}
                                   pagination={false}
                            />
                        </div>
                    }
                </div>

            </div>
            <PlayersListPopup selectPlayer={setSelectedPlayer} visible={selectedPlayer} />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        activeBets: state.activeBetsList,
        loading: state.loading,
    }
}

const mapDispatchToProps = {
    getActiveBetList,
    clearActiveBetList
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ActiveBets));