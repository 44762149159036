import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {
    getTradingRate,
    putIsEnableTradingRate,
    getAssetStatus,
    resetSingleTradingRate,
    resetAssetStatus
} from "@actions/tradingRatesAction";
import EditTradingRates from "@components/EditTradingRates/EditTradingRates";
import {FormattedMessage, injectIntl} from 'react-intl';

import {Table, Tooltip, Switch, Input} from "antd";
import {SearchOutlined} from '@ant-design/icons';
import TableLoader from "./../../../components/Loaders/tableLoader"

import {useHistory} from "react-router-dom";
import {getUrlString, getUrlValue} from "../../../utils/CommonUtils";
import InfoPopUp from "@components/InfoPopUp/InfoPopUp";

function TradingRates({getTradingRate, tradingRates, putIsEnableTradingRate, intl, loading,
                          selectedAssetStatus, getAssetStatus, resetAssetStatus}) {

    let history = useHistory()
    const search = history && history.location && history.location.search

    const [tradingRatesTable, setTradingRatesTable] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [limitPage, setLimit] = useState(+getUrlValue(search).limitPage || 10)
    const [currentPage, setCurrentPage] = useState(+getUrlValue(search).currentPage || 1)
    const [searchValue, setSearchValue] = useState( getUrlValue(search).searchValue || "")
    const [sortDirDesc, setSortDirDesc] = useState(  getUrlValue(search).sortDirDesc === "desc")
    const [selectedRate, setSelectedRate] = useState({});
    const [selectedForEdit, setSelectedForEdit] = useState("")
    const serachInputRef = useRef();
    const stor = useRef({});

    useEffect(() => {
        stor.current.searchValue =  getUrlValue(search).searchValue || "";
        stor.current.currentPage = +getUrlValue(search).currentPage || 1;
        stor.current.limitPage = +getUrlValue(search).limitPage || 10;
        stor.current.sortDirDesc = getUrlValue(search).sortDirDesc === "desc";
        stor.current.isPopEvent = false;
        stor.current.beforeSearchPage = null;
        stor.current.beforeSearchLimit = null;
    }, [])


    const columns = [
        {
            title: intl.formatMessage({id: "logo"}),
            dataIndex: 'icon',
            className: 'noPadding',
            render: (text, record) => {
                return (
                    <div className="img">
                        {
                            record.icon ? <img src={record.icon}  alt="img"/> :
                                record.displayName.substring(0,2)
                        }

                    </div>

                    );
            },
            width: 65,

        },
        {
            title: intl.formatMessage({id: "name"}),
            className: 'drag-visible',
            dataIndex: 'displayName',
            ellipsis: true,
            sortOrder: sortDirDesc? "descend" : "ascend",
            sortDirections: ["descend","ascend"],
            showSorterTooltip: false,
            sorter: true,
            width:  "fit-content",
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                        setSortDirDesc(prev => !prev)
                    }
                };
            },
        },
        {
            title: intl.formatMessage({id: "actions"}),
            dataIndex: 'actions',
            align: 'right',
            className: "noPadding",
            render: (text, record) => {
                return (<div className="iconBox">
                    <Tooltip title={<FormattedMessage id='edit'/>} color={"#fff"} placement="bottom" >
                        <div className="edit-icon icon" onClick={() => {
                            setSelectedForEdit(record.id);
                        }}/>
                    </Tooltip>
                    <Tooltip title={ <FormattedMessage id={`${record.isEnabled ? "disabled" : "enabled"}`}/>  } color={"#fff"} placement="bottom">
                        <Switch checked={record.isEnabled} className="radioSwitch" loading={loading[`_is_enabled_trading_rate${record.id}`]}
                                onChange={() => {
                                    if(record.isEnabled) {
                                        getAssetStatus(record.key, record.id)
                                        setSelectedRate(record)
                                    } else {
                                        putIsEnableTradingRate(record.id, !record.isEnabled)
                                    }

                                }}/>
                    </Tooltip>
                </div>);
            },
        },
    ];

    const pushHistpry = (currentPage, limitPage, searchValue, sortDirDesc) => {
        const {params} =  getUrlString({currentPage, limitPage, searchValue: (searchValue?.length > 2 ? searchValue : ''), sortDirDesc: sortDirDesc? "desc": ''});
        history.push({search: params.toString()});
    }

    useEffect(() => {
        if (tradingRates) {
            setTradingRatesTable(tradingRates.items)
            setTotalPages(tradingRates.total)
        }
    }, [tradingRates]);

    const changeSearch = (e) => {
        stor.current.isPopEvent = false;
        setSortDirDesc(false)
        setSearchValue(e.target.value)
    }

    useEffect(() => {
        getTradingRate(currentPage, limitPage, searchValue, sortDirDesc);
        const {params} =  getUrlString({currentPage, limitPage,  searchValue, sortDirDesc});
        history.replace({search: params.toString()});
    }, [])

    useEffect(() => {
        if(selectedAssetStatus.betCount === 0  && !selectedAssetStatus.assetGroupStatus && selectedRate.id) {
            putIsEnableTradingRate(selectedRate.id, !selectedRate.isEnabled);

        }
    },[selectedAssetStatus])

    useEffect(() => {
        if (!stor.current.isPopEvent) {

            let p = currentPage, l = limitPage, isEqual = true;

            if (stor.current.searchValue && searchValue.length > stor.current.searchValue.length) {
                if (searchValue.length > 2) {
                    if (!(stor.current.beforeSearchPage && stor.current.beforeSearchLimit)) {
                        stor.current.beforeSearchPage = currentPage;
                        stor.current.beforeSearchLimit = limitPage;
                    }
                    p = 1;
                }
            } else {
                if (searchValue.length < 3) {
                    p = stor.current.beforeSearchPage || currentPage;
                    l = stor.current.beforeSearchLimit || limitPage;
                    stor.current.beforeSearchPage = null;
                    stor.current.beforeSearchLimit = null;
                } else if (searchValue.length < stor.current.searchValue.length) {
                    p = 1;
                }
            }
            
            if (p != currentPage || l != limitPage) {
                isEqual = false;
            }

            setCurrentPage(+p);
            setLimit(+l);
            if (!isEqual) {
                return;
            }
            

            if (typeof stor.current.currentPage != 'undefined' && stor.current.currentPage != currentPage ||
                typeof stor.current.limitPage != 'undefined' && stor.current.limitPage != limitPage ||
                (typeof stor.current.searchValue != 'undefined' && stor.current.searchValue != searchValue && searchValue.length > 2) ||
                (typeof stor.current.sortDirDesc != 'undefined' && stor.current.sortDirDesc != sortDirDesc)) {

                pushHistpry(currentPage, limitPage, searchValue, sortDirDesc)

            } else {
                if (searchValue.length < 3 && stor.current.searchValue?.length > 2) {
                    pushHistpry(currentPage, limitPage, '', sortDirDesc)
                }
            }
        }
        
       return () => {
           stor.current.searchValue = searchValue;
           stor.current.currentPage = currentPage;
           stor.current.limitPage = limitPage;
           stor.current.sortDirDesc = sortDirDesc;
       }
    }, [searchValue, currentPage, limitPage, sortDirDesc])



    useEffect(() => {
        return history.listen((location, action) => {
            const loc = location.search;
            const {sortDirDesc, currentPage, limitPage, searchValue} = getUrlValue(loc);
            if (loc) {
                if (action === 'POP') {
                    stor.current.isPopEvent = true;
                    setSearchValue(searchValue);
                    setCurrentPage(+currentPage);
                    setLimit(+limitPage);
                    setSortDirDesc(sortDirDesc == "desc");
                    getTradingRate(currentPage, limitPage, searchValue, sortDirDesc == "desc");
                } else if (action === 'PUSH') {
                    getTradingRate(currentPage, limitPage, searchValue, sortDirDesc == "desc");
                }
            }
        })
    },[history])

    return (
        <div className="content tradingRates">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='trading_rates'/></div>
            </div>
            <div className="tableSearch">
                <Input suffix={<SearchOutlined/>}
                       ref={serachInputRef}
                       autoComplete="off"
                       onChange={(e) => changeSearch(e)}
                       value={searchValue}
                />
            </div>
            <div className="mainTable">
                { loading._trading_rates ? <TableLoader count={limitPage} column={1} icon={true} drag={false} actions={2} /> :
                    <Table dataSource={tradingRatesTable}
                           columns={columns}
                           rowKey="id"
                           scroll={{x: "100%"}}
                           pagination={
                               {
                                   showSizeChanger: true,
                                   defaultCurrent: currentPage,
                                   defaultPageSize: limitPage,
                                   showLessItems: true,
                                   onChange: (page, limit) => {
                                    stor.current.isPopEvent = true;
                                        setLimit(l => {
                                            stor.current.isPopEvent = false;
                                            if (l === limit) {
                                                setCurrentPage(page);
                                            } else {
                                                setCurrentPage(1);
                                            }
                                            return limit;
                                        });
                                   },
                                   total: totalPages,
                                   showTotal: (total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`
                               }
                           }
                    />
                }
            </div>
            {
                selectedForEdit ? <EditTradingRates show={selectedForEdit} selectedForEdit={selectedForEdit} handleCancel={() => setSelectedForEdit("")}/> : ""
            }
            {selectedRate && (selectedAssetStatus.betCount || selectedAssetStatus.assetGroupStatus) ?
                <InfoPopUp show={selectedAssetStatus.betCount || selectedAssetStatus.assetGroupStatus}
                           onCancel={
                               () => {
                                   setSelectedRate("");
                                   resetAssetStatus();
                               }
                           }
                           onSubmit={
                               () => {
                                   putIsEnableTradingRate(selectedRate.id, !selectedRate.isEnabled);
                                   setSelectedRate("")}
                           }
                           loading={loading[`_is_enabled_trading_rate${selectedRate.id}`]}
                           title="do_you_want_to_continue"
                           description={selectedAssetStatus.betCount ? "trading_rates_confirm" : ""}
                           descriptionVariable={selectedAssetStatus.betCount ? {count: selectedAssetStatus.betCount} : ""}
                           descriptionSecond={selectedAssetStatus.assetGroupStatus ? "rate_is_in_the_active_group_and_will_be_removed_from_the_list" : " "}/> : ""}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        tradingRates: state.tradingRatesReducer.tradingRates,
        loading: state.loading,
        selectedAssetStatus: state.selectedAssetStatus,
    }
}

const mapDispatchToProps = {
    getTradingRate,
    putIsEnableTradingRate,
    getAssetStatus,
    resetSingleTradingRate,
    resetAssetStatus
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TradingRates));