export default {
    settings: {
        lang: {
            id: 1
        }
    },
    user: null,
    emptyObj: {},
    emptyArr: [],
    playersList: {
        list: [],
        listModalVisible: false,
        isLoading: false,
    },
    ratesGroupConfig: {
        ratesGroupsConfigs :[],
        id: "",
        ratesGroup: []
    },
    assetsStatus: {
        betCount: "",
        assetGroupStatus: false
    },
    tradingRates : {
        total: 0,
        items: []
    },
    allowCancel: {
        allowBets: false,
        allowTransaction: false
    }
}