import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {
    getRatesGroups,
    deleteRatesGroups,
    sortRatesGroupsConfigs,
    putIsEnableRatesGroup,
    setRatesGroups
} from "@actions/ratesGroupsActions";
import ControlRatesGroups from "@components/ControlRatesGroups/ControlRatesGroups";
import arrayMove from 'array-move';
import {Table, Tooltip, Switch} from "antd";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlString, getUrlValue} from "../../../utils/CommonUtils";
import TableLoader from "@components/Loaders/tableLoader";
import InfoPopUp from "@components/InfoPopUp/InfoPopUp";
import DragHandle from "@components/DragAndDrope/DragHandle";
import DraggableContainer from "./DraggableContainer";
import DraggableBodyRow from "./DraggableBodyRow";
import NoData from "@components/NoData/NoData";

function RatesGroups({
                         getRatesGroups,
                         deleteRatesGroups,
                         ratesGroups,
                         intl,
                         sortRatesGroupsConfigs,
                         loading,
                         putIsEnableRatesGroup,
                         setRatesGroups
                     }) {
    let history = useHistory();
    const search = useRef(history && history.location && history.location.search)

    const [controlModal, setControlModal] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [limitPage, setLimitPage] = useState(+ getUrlValue(search.current).limitPage || 10);
    const [currentPage, setCurrentPage] = useState(+ getUrlValue(search.current).currentPage || 1);
    const [confirmDelete, setConfirmDelete] = useState("");
    const [singleGroup, setSingleGroup] = useState({})

    const changePage = (page , pageSize ) => {
        let newPage = 1;

        setLimitPage(l => {
            if (l === pageSize) newPage = page;
            setCurrentPage(newPage);
            return pageSize;
        });

        const {params} =  getUrlString({currentPage: newPage, limitPage: pageSize})
        history.push({search: params.toString()})
    }

    const columns = [
        {
            className: 'drag-visible noPadding',
            render: () => <DragHandle/>,
            width: 40,
        },
        {
            title: <FormattedMessage id='logo'/>,
            dataIndex: 'icon',
            className: 'drag-visible noPadding',
            width: 65,
            render: (text, record) => {
                return (<img src={record.icon} className="img"/>);
            },

        },
        {
            title: <FormattedMessage id='name'/>,
            className: 'drag-visible',
            dataIndex: 'name',
        },
        {
            title: <FormattedMessage id='actions'/>,
            dataIndex: 'actions',
            align: 'right',
            key: 'id',
            className: "noPadding",
            shouldCellUpdate: (record, prevRecord) => record.id == prevRecord.id,
            render: (text, record) => (
                <div className="iconBox" key={record.id}>
                    <Tooltip title={<FormattedMessage id='edit'/>} color={"#fff"} placement="bottom">
                        <div className="edit-icon icon" onClick={
                            () => {
                                setControlModal("edit");
                                setSingleGroup(record)
                            }
                        }
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='delete'/>} color={"#fff"} placement="bottom">
                        <div className="delete-icon icon" onClick={() => setConfirmDelete(record.id)}/>
                    </Tooltip>
                    <Tooltip title={ <FormattedMessage id={`${record.isEnabled ? "disabled" : "enabled"}`}/>  } color={"#fff"} placement="bottom">
                        <Switch checked={record.isEnabled} loading={loading[`_is_enabled_rates_group${record.id}`]}
                                onChange={() => putIsEnableRatesGroup(record.id, !record.isEnabled)}
                                className="radioSwitch"/>
                    </Tooltip>
                </div>)
            ,
        },
    ];

    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(ratesGroups.items || []), oldIndex, newIndex).filter(el => !!el);
            setRatesGroups({items: newData, total: totalPages});
            const body = JSON.parse(JSON.stringify(newData));
            body.forEach((item, index) => {
                item.index = (index + 1) + (currentPage - 1) * limitPage;
            })
            sortRatesGroupsConfigs(body)
        }
    };

    useEffect(() => {
        if (ratesGroups && ratesGroups.items) {
            setTotalPages(ratesGroups.total);
        }
    }, [ratesGroups]);

    useEffect(() => {
        return history.listen((location) => {
            const loc = location.search
            if (loc) {
                getRatesGroups(getUrlValue(loc).currentPage, getUrlValue(loc).limitPage)
                setCurrentPage(+getUrlValue(loc).currentPage || currentPage)
                setLimitPage(+getUrlValue(loc).limitPage || limitPage)
            } else {
                setCurrentPage(1)
                setLimitPage(10)
            }
        })
    },[history])

    useEffect(() => {
        const {params} =  getUrlString({currentPage, limitPage});
        history.replace({search: params.toString()});
    }, [])

    return (
        <div className="content ratesGroup">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='rates_groups'/></div>
                {
                    ratesGroups.items && ratesGroups.items.length ?
                        <div className="titleArea--BtnGroup">
                            <button className="mainBtn" onClick={
                                () => {
                                    setControlModal("add");
                                }
                            }>
                                <div className="icon"/>
                                <FormattedMessage id='add_rates_groups'/>
                            </button>
                        </div> : ""
                }

            </div>
            {
                ratesGroups.items && ratesGroups.items.length || loading._rates_groups ?
                    <div className="mainTable">
                        {
                            loading._rates_groups ?
                                <TableLoader count={limitPage} column={1} drag={true} actions={3}/> :
                                <>
                                    <Table
                                        dataSource={ratesGroups.items || []}
                                        columns={columns}
                                        scroll={{x: "100%"}}
                                        rowKey={record => record.id}
                                        components={{
                                            body: {
                                                wrapper: (props) => <DraggableContainer
                                                    onSortEnd={onSortEnd} {...props} />,
                                                row: (props) => <DraggableBodyRow {...props}
                                                                                  ratesGroups={ratesGroups.items || []}/>,
                                            },
                                        }}
                                        pagination={
                                            {
                                                showSizeChanger: true,
                                                defaultCurrent: currentPage,
                                                defaultPageSize: limitPage,
                                                showLessItems: true,
                                                onChange: changePage,
                                                total: totalPages,
                                                showTotal: (total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`
                                            }
                                        }/>
                                </>
                        }

                    </div>
                    : <NoData add={() => setControlModal("add")}/>
            }

            {controlModal ? <ControlRatesGroups show={controlModal}
                                                group={singleGroup}
                                                handleCancel={
                                                    () => {
                                                        setControlModal("")
                                                        setSingleGroup({})
                                                    }
                                                }
            /> : ""}
            {confirmDelete ? <InfoPopUp show={confirmDelete}
                                        onCancel={() => setConfirmDelete("")}
                                        onSubmit={() => {deleteRatesGroups(confirmDelete)}}
                                        loading={loading._delete_rates_group}
                                        title="delete_group"
                                        description="delete_group_description"/> : ""}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        ratesGroups: state.ratesGroupsReducer,
        loading: state.loading,
    }
}

const mapDispatchToProps = {
    getRatesGroups,
    deleteRatesGroups,
    sortRatesGroupsConfigs,
    putIsEnableRatesGroup,
    setRatesGroups
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RatesGroups));