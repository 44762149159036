import {combineReducers} from "redux";
import settings from "./settingsReducer";
import {
    userReducer as user,
    isEndSilentRequestReducer as isEndSilentRequest
 } from "./userReducer";
import ratesGroupsReducer from "./ratesGroup/ratesGroupsReducer";
import tradingRatesReducer from "./tradingRates/tradingRatesReducer";
import singleTradingRateReducer from "./tradingRates/singleTrandingRateReducer";
import ratesGroupsConfigsReducer from "./ratesGroup/ratesGroupsConfigsReducer";
import langList from "./translation/langListReducer";
import translations from "./translation/translationsReducer";
import translationDeletedIcon from "./translation/translationTrashedIcon";
import minMaxDurations from "./settings/minMaxDurationsReducer";
import clientsList from "./clients/clientsListReducer";
import error from "./errorReducer";
import translationUpdated from "./translation/translationUpdateReducer";
import betsList from "./bets/betListReducer";
import betsListFilter from "./bets/betListFilterReducer";
import transactionsFilter from "./transactions/transactionsFilterReducer";
import transactionsList from "./transactions/transactionListReducer";
import players from "./playersReducer";
import activeBetsList from "./activeBets/activeBetsListReducer";
import selectedAssetStatus from "./tradingRates/selectedAssetStatusReducer";
import providersAssets from "./providers/providersAssetsReducer";
import loading from "./loadingReducer";
import responseErrors from "./responseErrorsReducer";
import path from "./pathReducer";
import csvAllowCancel from "./csvAllowCancelReducer";
import singleClient from "./clients/singleClientReducer";

const rootReducer = combineReducers({
    settings,
    user,
    isEndSilentRequest,
    ratesGroupsReducer,
    tradingRatesReducer,
    singleTradingRateReducer,
    ratesGroupsConfigsReducer,
    langList,
    translations,
    translationDeletedIcon,
    minMaxDurations,
    clientsList,
    error,
    translationUpdated,
    betsList,
    betsListFilter,
    players,
    transactionsFilter,
    transactionsList,
    activeBetsList,
    selectedAssetStatus,
    providersAssets,
    loading,
    responseErrors,
    path,
    csvAllowCancel,
    singleClient
});

export default rootReducer;
