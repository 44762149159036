import React, {useEffect, useState} from 'react';
import { Menu } from 'antd';

  import { Link,  useLocation, useHistory } from "react-router-dom";
  import {FormattedMessage} from "react-intl";
  import "./style.sass";
  import ratesGroupIcon from "@assets/img/icon/ratesGroupIcon.svg";
  import tradingRatesIcon from "@assets/img/icon/tradingRatesIcon.svg";
  import translationsIcon from "@assets/img/icon/translations_icon.svg";
  import settingsIcon from "@assets/img/icon/settings.svg";
  import clientsIcon from "@assets/img/icon/clients.svg";
  import betsIcon from "@assets/img/icon/bets.svg";
  import transactionsIcon from "@assets/img/icon/transactions.svg";
  import activeBetsIcon from "@assets/img/icon/active_bets.svg";
  import providersIcon from "@assets/img/icon/providers.svg";

function LeftBare() {
    const localHistory = useHistory();
    let location = useLocation();
    const [path, setPath] = useState(localHistory.location.pathname)

    const getPathParams = (path) => {
        let currentPath = location.pathname;
        let params = '';
        if (currentPath.indexOf(path) !== -1) {
            params = location.search;
        }
        return path + params;
    }

    useEffect(() => {
        setPath(location.pathname)
    },[location])

    return (
        <Menu  mode="inline" selectedKeys={[path]} className='left-bar-block'>
            <Menu.Item key="/active_bets" icon={<img src={activeBetsIcon}/>}>
                <Link  to={getPathParams("/active_bets")}><FormattedMessage id='active_bets' /></Link >
            </Menu.Item>
            <Menu.Item key="/bets" icon={<img src={betsIcon}/>}>
                <Link  to={getPathParams("/bets")}><FormattedMessage id='bets' /></Link >
            </Menu.Item>
            <Menu.Item key="/transactions" icon={<img src={transactionsIcon}/>}>
                <Link  to={getPathParams("/transactions")}><FormattedMessage id='transactions' /></Link >
            </Menu.Item>
            <Menu.Item key="/trading_rates" icon={<img src={tradingRatesIcon}/>}>
                <Link  to={getPathParams("/trading_rates")}><FormattedMessage id='trading_rates' /></Link >
            </Menu.Item>
            <Menu.Item key="/rates_groups" icon={<img src={ratesGroupIcon}/>} >
                <Link  to={getPathParams("/rates_groups")}><FormattedMessage id='rates_groups' /></Link >
            </Menu.Item>
            <Menu.Item key="/clients" icon={<img src={clientsIcon}/>}>
                <Link  to={getPathParams("/clients")}><FormattedMessage id='clients' /></Link >
            </Menu.Item>
            <Menu.Item key="/providers" icon={<img src={providersIcon}/>}>
                <Link  to={getPathParams("/providers")}><FormattedMessage id='providers' /></Link >
            </Menu.Item>
            <Menu.Item key="/translations" icon={<img src={translationsIcon}/>}>
                <Link  to={getPathParams("/translations")}><FormattedMessage id='translations' /></Link >
            </Menu.Item>
            <Menu.Item key="/settings" icon={<img src={settingsIcon}/>}>
                <Link  to={getPathParams("/settings")}><FormattedMessage id='settings' /></Link >
            </Menu.Item>
            
        </Menu>
    )
}

export default LeftBare;