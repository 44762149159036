import React from "react";
import {FormattedMessage} from "react-intl";
import { Empty } from 'antd';

const NoData = ({add}) => {
    return (
        <div className="notData">
            <div className="iconBox"></div>
            <div className="infoBox">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description=""/>
                <div className="infoBox--title"><FormattedMessage id='no_data' /></div>
                <div className="infoBox--description"><FormattedMessage id='add_rates_groups' /></div>
            </div>
            <div className="btnGroup">
                <button className="mainBtn" onClick={add}>
                    <div className="icon" /> <FormattedMessage id='add_rates_groups' />
                </button>
            </div>
        </div>
    )
}

export default NoData