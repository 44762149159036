import initialStore from "../../initialStore";
import {
    SET_TRANSLATIONS,
    SET_EDIT_TRANSLATIONS,
    REQUEST_ADD_TRANSLATIONS_ICON
} from "@actions/actionTypes";

export default function langListReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_TRANSLATIONS:
            return  action.translations
        case SET_EDIT_TRANSLATIONS:
            let newTranslations = state.items.map(item => {
                const selectedItem = action.translations.items.filter(translate => translate.key === item.key )
                if (selectedItem.length) {
                    return {
                        ...item,
                        ...selectedItem[0]
                    }
                };
                return item;
            });
            return {
                total: state.total,
                items: newTranslations
            };
        case REQUEST_ADD_TRANSLATIONS_ICON:
            const updatedTranslations = JSON.parse(JSON.stringify( state.items))
            const index = updatedTranslations.findIndex(item => item.key === action.translations.name)
            updatedTranslations[index] = { ...updatedTranslations[index], ...action.translations }
            return {
                total: state.total,
                items: updatedTranslations
            };
        default:
            return state;
    }
}
