import {
    SET_PLAYERS_BY_IP,
    REQUEST_PLAYERS_BY_IP,
    CLEAR_PLAYERS_BY_IP
} from "@actions/actionTypes";
import initialStore from "../initialStore";

function playersReducer(state = initialStore.playersList, action) {
    switch (action.type) {
        case SET_PLAYERS_BY_IP:
            return {
                list: action.players,
                listModalVisible: true,
                isLoading: false
            }
        case CLEAR_PLAYERS_BY_IP:
            return initialStore.playersList;
        case REQUEST_PLAYERS_BY_IP:
            return { ...initialStore.playersList, isLoading: true};
        default:
            return state;
    }
}

export default playersReducer