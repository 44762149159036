import React, {useEffect, useRef, useState, createRef, useContext} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from 'react-intl';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import {
    editSingleTradingRate,
    getSingleTradingRate
} from "@actions/tradingRatesAction";
import {secondsToHms, HmsToseconds, sortDurationsArray, customFormat} from "@services/TradingRatesService";
import classNames from "classnames";
import {Modal, Form, Input, Row, Col, Switch, Slider, Select, TimePicker, Space, Radio, Tabs, Button} from 'antd';
import ImgUpload from "../ImgUpload/ImgUpload";
import {marks} from "@constants/Enums"
import TradingRatesModalLoader from "@components/Loaders/tradingRatesModalLoader";
import {REQUEST_EDIT_SINGLE_TRADING_RATES} from "@actions/actionTypes";
import {ResponseContext} from "../../hooks/ResponseObserver";

const weekDays = [
    {
        number: 0,
        name: <FormattedMessage id='sunday'/>
    },
    {
        number: 1,
        name: <FormattedMessage id='monday'/>
    },
    {
        number: 2,
        name: <FormattedMessage id='tuesday'/>
    },
    {
        number: 3,
        name: <FormattedMessage id='wednesday'/>
    },
    {
        number: 4,
        name: <FormattedMessage id='thursday'/>
    },
    {
        number: 5,
        name: <FormattedMessage id='friday'/>
    },
    {
        number: 6,
        name: <FormattedMessage id='saturday'/>
    }
]


function EditTradingRates({show, handleCancel, intl, singleTradingRate, editSingleTradingRate, loading,
                              selectedForEdit, getSingleTradingRate}) {
    const {Option} = Select;
    const [form] = Form.useForm();
    const durationRefs = useRef([]);
    const [durationListLastKey, setDurationListLastKey] = useState(null);
    const [periods, setPeriodsState] = useState({});
    const [groupChange, setGroupChange] = useState(false);
    const [tabMode, setTabMode] = useState("1")
    const [periodError, setPeriodError] = useState(null)
    const [isValidDurations, setIsValidDurations] = useState(false)
    const [firstTabError,setFirstTabError] = useState(false);

    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    useEffect(() => {
        getSingleTradingRate(selectedForEdit)
    }, [])

    const onFinish = (data) => {
        const durations = [], periodsList = [];
        data.durations.forEach(item => durations.push({
            time: HmsToseconds(item[`durations${item.elemIndex}`] + "" + item[`timeSelector${item.elemIndex}`]),
            payout: item[`payout${item.elemIndex}`]
        }))
        if(groupChange) {
            weekDays.map(day => {
                periods[0] && periods[0].forEach(period => {
                    periodsList.push({...period,day: day.number})
                })

            })
        }else if (periods && Object.keys(periods)) {
            Object.keys(periods).forEach(key => {
                periodsList.push(...periods[key])
            })
        }

        editSingleTradingRate(singleTradingRate.id, {
            ...data,
            periods: periodsList,
            durations,
            key: singleTradingRate.key,
            isEnabled: singleTradingRate.isEnabled
        });
    }

    const setPeriods = (p) => {
        for (let k in p) {
            p[k].sort((a, b) => moment(a.start, "HH:mm:ss") - moment(b.start, "HH:mm:ss"))
        }
        setPeriodsState(p);
    }


    useEffect(() => {
        if (singleTradingRate.id) {
            let newObj = {...singleTradingRate, periods: [], durations: [], icon: singleTradingRate.icon || ""}, listItems = [], periodsList = {};
            const scopeDuration = JSON.parse(JSON.stringify(singleTradingRate.durations));

            scopeDuration.sort((a,b) => a.time - b.time).forEach((item, idx) => {
                const {date, charset} = secondsToHms(item.time);
                listItems.push({
                    ["durations" + idx]: date,
                    ["payout" + idx]: item.payout,
                    ["timeSelector" + idx]: charset,
                    elemIndex: idx
                })
            });

            singleTradingRate.periods.forEach(item => {
                periodsList[item.day] = periodsList[item.day] ? [...periodsList[item.day],item ] : [item]
            })
            weekDays.forEach(weekday => newObj.periods.push({[`period${weekday.number}`]: [moment("", "HH:mm:ss"),moment("", "HH:mm:ss")]}))
            setPeriods(periodsList)
            durationRefs.current = scopeDuration.map((_, i) => durationRefs.current[i] ?? createRef());
            if(form){
                form.setFieldsValue({...newObj, "durations": listItems});
            }

        }
    }, [singleTradingRate]);


    const selectGroupChange = () => {
        if(!groupChange) {
            const periodsList = [];
            const updatedPeriods = {}
            Object.keys(periods).forEach(key => {
                periodsList.push(...periods[key])
            })
            const firstDayPeriods = periodsList.filter(period => period.day === 0)

            weekDays.forEach(weekDay => {
                firstDayPeriods.forEach(item => {
                    updatedPeriods[weekDay.number] ? updatedPeriods[weekDay.number].push({...item, day: weekDay.number}) :updatedPeriods[weekDay.number] = [{...item, day: weekDay.number}]
                })
            })
            setPeriods(updatedPeriods)
        }

        setGroupChange(prev => !prev);
    }

    const handleModeChange = (e) => {
        const mode = e.target.value;
        setTabMode(mode);
    }

    const addPeriod = (date, day) => {
        const periodsObj = JSON.parse(JSON.stringify(periods))
        const element = {
            day,
            start: moment(date[0], "HH:mm:ss").format("HH:mm:ss"),
            end: moment(date[1], "HH:mm:ss").format("HH:mm:ss")
        };

        let isValid = true


        periodsObj[day] ?  periodsObj[day].push(element) :  periodsObj[day] = [element]



        if (moment(element.start,"HH:mm:ss").valueOf() === moment(element.end,"HH:mm:ss").valueOf()) {
            isValid = true
        } else if (periodsObj[day].length === 1) {
            isValid = false
        } else {
            periodsObj[day].sort((a,b) => moment(a.start,"HH:mm:ss").valueOf() - moment(b.start,"HH:mm:ss").valueOf())
            for(let i = 1; i < periodsObj[day].length; ++i){
                if( moment(periodsObj[day][i - 1].start,"HH:mm:ss").valueOf() !== moment(periodsObj[day][i - 1].end,"HH:mm:ss").valueOf()
                    &&  moment(periodsObj[day][i - 1].end,"HH:mm:ss").valueOf() <=  moment(periodsObj[day][i].start,"HH:mm:ss").valueOf()) {
                    isValid = false
                } else {
                  isValid = true
                  break
                }
            }

        }

        if(isValid) {
            const index = periodsObj[day].indexOf(element);
            if (index > -1) {
                periodsObj[day].splice(index,1)
            }
            setPeriodError(day)
            setTimeout(() => setPeriodError(null), 3000)

        } else if (groupChange) {
            weekDays.forEach(weekDay => {
                if (weekDay.number) {
                    periodsObj[weekDay.number] ?  periodsObj[weekDay.number].push({...element, day: weekDay.number}) :  periodsObj[weekDay.number] = [{...element, day: weekDay.number}]
                }
            })
        }

        setPeriods(periodsObj)
        const formValue = form.getFieldsValue();
        form.resetFields([`periods`]);
        form.setFieldsValue(formValue)
    }

    const deletePeriod = (item,day) => {
        const periodsObj = JSON.parse(JSON.stringify(periods))
        if(groupChange) {
            weekDays.forEach(weekDay => {
                const index = periodsObj[weekDay.number].findIndex(period => period.start === item.start && period.end === item.end)
                periodsObj[weekDay.number].splice(index,1)
            })
        } else {
            const index = periodsObj[day].findIndex(period => period.start === item.start && period.end === item.end)
            periodsObj[day].splice(index,1)
        }
        setPeriods(periodsObj)
    }

    const sortDurations = () => {
        if(detectDurationsErrors()) return;
        const formValue = JSON.parse(JSON.stringify(form.getFieldsValue()));
        const f = form.getFieldsValue();
        formValue[`durations`].sort(sortDurationsArray);
        f[`durations`].forEach((item, index) => {
            let el = formValue[`durations`][index];
            item['durations' + item.elemIndex] = el['durations' + el.elemIndex];
            item['timeSelector' + item.elemIndex] = el['timeSelector' + el.elemIndex];
            item['payout' + item.elemIndex] = el['payout' + el.elemIndex];
        })

        form.setFieldsValue(f);
    }

    const detectDurationsErrors = () => {
        const formValue = JSON.parse(JSON.stringify(form.getFieldsValue()));
        const isValid = form.getFieldsError().some((field) => {
            if( field.name.includes("durations")) {
                return  field.errors.length > 0
            }
        })
        let isEmpty =  false

        formValue['durations'].forEach(item => {
            if (!item[`payout${item.elemIndex}`] || !item[`durations${item.elemIndex}`] || !item[`timeSelector${item.elemIndex}`]) {
                isEmpty = true
            }
        })

        setIsValidDurations(isValid || isEmpty )
        return isValid || isEmpty
    }


    const formValueChange = () => {
        setFirstTabError(false)
        const isValid = form.getFieldsError().some((field) => {
            return  field.errors.length > 0
        })
        if (isValid) {
            setFirstTabError(true)
        }
    }

    useEffect( () => {
        formValueChange()
    }, [tabMode])

    useEffect(() => {
        subscribeForError(REQUEST_EDIT_SINGLE_TRADING_RATES, () => {
            handleCancel();
        }, selectedForEdit);

        return () => {
            unSubscribeForError(REQUEST_EDIT_SINGLE_TRADING_RATES);
        }
    }, [])

    return (
        <Modal
            title={<FormattedMessage id='edit_trading_rates'/>}
            centered
            visible={show}
            width={897}
            onCancel={() => handleCancel()}
            className={`formModal ${loading[`_single_trading_rates${selectedForEdit}`] ? "loading" : ""}`}
            footer={false}>
            {
                loading[`_single_trading_rates${selectedForEdit}`] ? <TradingRatesModalLoader/> :
                    <>
                        <div className="tabMode centeredBox">
                            <Radio.Group onChange={handleModeChange} value={tabMode} className={classNames({invalid: firstTabError ||periodError})}>
                                <Radio.Button className={classNames("centeredBox", {invalid: firstTabError})} value="1"><FormattedMessage id={'details'}/></Radio.Button>
                                <Radio.Button className={classNames("centeredBox", {invalid: periodError})} value="2"><FormattedMessage
                                    id={'week_day_and_time'}/></Radio.Button>
                            </Radio.Group>
                        </div>
                        <Form
                            layout="vertical"
                            form={form}
                            name="trading-form"
                            className="trading-form"
                            onFinish={onFinish}
                            onFieldsChange={formValueChange}
                            scrollToFirstError
                            validateTrigger="onBlur">
                            <Tabs activeKey={tabMode} className="overflowContent" >
                                <Tabs.TabPane key="1">
                                    <Row className="MainModal">
                                        <Col span={12} className="MainModal--upload MainModal--parts">
                                            <div className="MainModal--parts--content">
                                                <Form.Item
                                                    name="displayName"
                                                    label={<FormattedMessage id='name'/>}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: <FormattedMessage id='name_validator'/>
                                                        },
                                                        ({getFieldValue}) => ({
                                                            validator(_, value) {

                                                                if (value && !(value.length >= 3 && value.length <= 256)) {
                                                                    return Promise.reject(intl.formatMessage({id: `name_valid`}))
                                                                }

                                                                return Promise.resolve();
                                                            }
                                                        })
                                                    ]}

                                                >
                                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: `type_name`})}/>
                                                </Form.Item>
                                                <Form.Item name="icon" label={<FormattedMessage id='upload_logo'/>}
                                                           className="tradingImgUpload">
                                                    <ImgUpload/>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col span={12} className="MainModal--parts">
                                            <div className="durations">
                                                <Form.List name="durations">
                                                        {(fields, {add, remove}) => (
                                                            <>
                                                                {fields.map(({key, name, fieldKey, ...restField}, index) => (
                                                                    <React.Fragment key={key}>
                                                                        <Space
                                                                            style={{
                                                                                display: 'flex',
                                                                                marginBottom: 8,
                                                                                alignItems: "flex-start"
                                                                            }}
                                                                            align="baseline">
                                                                            <Form.Item
                                                                                name={[name, `durations${key}`]}
                                                                                {...restField}
                                                                                fieldKey={[fieldKey, `durations${key}`]}
                                                                                onBlur={() => setTimeout(() => {
                                                                                    sortDurations();
                                                                                }, 10)}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: <FormattedMessage
                                                                                            id='duration_validator'/>
                                                                                    },
                                                                                    ({getFieldValue}) => ({
                                                                                        validator(_, value) {
                                                                                            const durations = form.getFieldsValue().durations
                                                                                            const existingDuration = durations.filter(item => HmsToseconds(`${item[`durations${item.elemIndex}`]}${item[`timeSelector${item.elemIndex}`]}`) === HmsToseconds(`${value}${durations[index][`timeSelector${durations[index].elemIndex}`]}`))
                                                                                            const reg = new RegExp(/^\d+$/);

                                                                                            if (HmsToseconds(`${value}${durations[index][`timeSelector${durations[index].elemIndex}`]}`) < 5) {
                                                                                                return Promise.reject(intl.formatMessage({id: `duration_cant_be_less_than`},{minLimit: 5}));
                                                                                            }


                                                                                            if (value && !reg.test(value) ) {
                                                                                                return Promise.reject(intl.formatMessage({id: `duration_must_be_only_number`}));
                                                                                            }

                                                                                            if (existingDuration && existingDuration.length <= 1) {
                                                                                                sortDurations()
                                                                                                return Promise.resolve();
                                                                                            }
                                                                                            return Promise.reject(intl.formatMessage({id: `duration_is_existing`}));


                                                                                        },
                                                                                    })
                                                                                ]}


                                                                            >
                                                                                <Input
                                                                                    autoComplete="off"
                                                                                    placeholder={intl.formatMessage({id: "durations"})}
                                                                                    ref={durationRefs.current[key]}

                                                                                    addonAfter={
                                                                                        <Form.Item noStyle
                                                                                                   {...restField}
                                                                                                   fieldKey={[fieldKey, 'timeSelector']}
                                                                                                   name={[name, `timeSelector${key}`]}
                                                                                                   dependencies={["durations", name, `durations${key}`]}
                                                                                                   validateTrigger="onChange">
                                                                                            <Select className="select-after"
                                                                                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                                                                                    onChange={(value) => {
                                                                                                        const durations = form.getFieldsValue().durations
                                                                                                        const existingDuration = durations.filter(item => HmsToseconds(`${item[`durations${item.elemIndex}`]}${item[`timeSelector${item.elemIndex}`]}`) === HmsToseconds(`${durations[index][`durations${durations[index].elemIndex}`]}${value}`))
                                                                                                        const DurationValue = form.getFieldsValue([`durations`])[`durations`][index][`durations${key}`];
                                                                                                        durationRefs.current[key].current.props.onChange(DurationValue)
                                                                                                        if (existingDuration && existingDuration.length <= 1) {
                                                                                                            durationRefs.current[key].current.props.onBlur()
                                                                                                            setTimeout(() => {
                                                                                                                sortDurations();
                                                                                                            }, 10)
                                                                                                        }

                                                                                                    }}>
                                                                                                <Option value="h"><FormattedMessage
                                                                                                    id='hour'/></Option>
                                                                                                <Option value="m"><FormattedMessage
                                                                                                    id='minute'/></Option>
                                                                                                <Option value="s"><FormattedMessage
                                                                                                    id='sec'/></Option>
                                                                                            </Select>
                                                                                        </Form.Item>

                                                                                    }/>
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, `payout${key}`]}
                                                                                fieldKey={[fieldKey, 'payout']}
                                                                                onBlur={() =>
                                                                                    setTimeout(() => {
                                                                                        sortDurations();
                                                                                    }, 10)}
                                                                                rules={[{
                                                                                    required: true,
                                                                                    message: <FormattedMessage
                                                                                        id='payout_validator'/>
                                                                                },
                                                                                    ({getFieldValue}) => ({
                                                                                        validator(_, value) {
                                                                                            if (value && value <= 1) {
                                                                                                return Promise.reject(intl.formatMessage({id: `payout_cant_be_less_than_1`}));
                                                                                            }

                                                                                            const afterDotRegExp = new RegExp(/^\d+(\.\d{0,2})?$/);

                                                                                            if (value && !afterDotRegExp.test(value)) {
                                                                                                return Promise.reject(intl.formatMessage({id: `after_dot_must_be_two_digit`}));
                                                                                            }

                                                                                            sortDurations()
                                                                                            return Promise.resolve();

                                                                                        }
                                                                                    })
                                                                                ]}
                                                                            >
                                                                                <Input autoComplete="off"
                                                                                       onChange={({target}) => {
                                                                                           const value = target.value
                                                                                           const afterDotRegExp = new RegExp(/^\d+(\.\d{0,2})?$/);
                                                                                           if (value && !afterDotRegExp.test(value)) {
                                                                                               const formValue = form.getFieldsValue()
                                                                                               formValue.durations[name] = {
                                                                                                   [`durations${formValue.durations[name].elemIndex}`]: formValue.durations[name][`durations${formValue.durations[name].elemIndex}`],
                                                                                                   [`payout${formValue.durations[name].elemIndex}`]: value.substring(0, value.length - 1),
                                                                                                   [`timeSelector${formValue.durations[name].elemIndex}`]: formValue.durations[name][`timeSelector${formValue.durations[name].elemIndex}`],
                                                                                                   elemIndex: formValue.durations[name].elemIndex
                                                                                               }
                                                                                               form.setFieldsValue(formValue)
                                                                                               detectDurationsErrors()
                                                                                           }
                                                                                       }}
                                                                                       placeholder={intl.formatMessage({id: "payout"})}
                                                                                />
                                                                            </Form.Item>
                                                                            {

                                                                                <button type={"button"}
                                                                                        className="durationsBtn remove"
                                                                                        disabled={fields.length === 1 }
                                                                                        onClick={(e) => {
                                                                                            const formValue = form.getFieldsValue()
                                                                                            e.preventDefault()
                                                                                            if (!durationListLastKey || durationListLastKey < key) {
                                                                                                setDurationListLastKey(formValue.durations[fields.length - 1].elemIndex)
                                                                                            }
                                                                                            remove(name)
                                                                                            setTimeout(() => {
                                                                                                formValueChange();
                                                                                                detectDurationsErrors();
                                                                                            }, 10);
                                                                                        }}/>
                                                                            }
                                                                        </Space>
                                                                        {
                                                                            index === fields.length - 1 ?
                                                                                <button type={"button"} className="btn_1"
                                                                                        disabled={isValidDurations}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault()
                                                                                            const formValue = form.getFieldsValue()
                                                                                            const elemIndex = durationListLastKey > key ? durationListLastKey + 1 : key + 1
                                                                                            formValue.durations[fields.length] = {
                                                                                                [`durations${elemIndex}`]: "",
                                                                                                [`payout${elemIndex}`]: "",
                                                                                                [`timeSelector${elemIndex}`]: "s",
                                                                                                elemIndex
                                                                                            }
                                                                                            form.setFieldsValue(formValue)
                                                                                            durationRefs.current.push(createRef())
                                                                                            detectDurationsErrors()
                                                                                        }}><FormattedMessage id={"add"}/>
                                                                                        <div className="icon"/>
                                                                                </button>
                                                                                : ""
                                                                        }
                                                                    </React.Fragment>
                                                                ))}

                                                            </>
                                                        )}
                                                    </Form.List>
                                            </div>
                                            <hr className="divider"/>
                                            <div className="MainModal--parts--content">
                                                <Form.Item
                                                    label={<FormattedMessage id='average_rate'/>}
                                                    className="formHorizontal"
                                                    name="isRateAverage"
                                                    valuePropName="checked">
                                                    <Switch className="radioSwitch"/>
                                                </Form.Item>
                                            </div>
                                            <div className="MainModal--parts--content">
                                                <Form.Item label={<FormattedMessage id='precision'/>} name="fractDigits">
                                                    <Slider marks={marks} step={1} min={0} max={5} className="mainRange"/>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane key="2">
                                    <div className="MainModal">
                                        <div className="MainModal--parts">
                                            <div className="MainModal--parts--content">
                                                <div className="selectWeekDays">
                                                    <p className="selectDescription"><FormattedMessage id='set_Start_End'/></p>
                                                    <Switch className="radioSwitch" checked={groupChange}
                                                            onChange={selectGroupChange}/>
                                                </div>
                                                <Form.List name="periods">
                                                    {(fields) => (
                                                        <>
                                                            {fields.map(({key, name, fieldKey, ...restField}, index) => {
                                                                let elemKey = key
                                                                groupChange ? elemKey =0 : null
                                                                return (
                                                                    <div key={key} className={`periodsBox`}>
                                                                        <Form.Item
                                                                            name={[name, `periods${key}`]}
                                                                            className={`range-time-picker formHorizontal`}
                                                                            label={weekDays[key].name}
                                                                            {...restField}
                                                                            fieldKey={[fieldKey, `periods${key}`]}
                                                                            normalize>
                                                                            <div className="pickerBox">
                                                                                <div>
                                                                                    <TimePicker.RangePicker
                                                                                        disabled={groupChange && index}
                                                                                        allowClear={false}
                                                                                        suffixIcon={false}
                                                                                        onChange={(date) => addPeriod(date, key)}
                                                                                        placeholder={[`${intl.formatMessage({id: "start_time"})} (UTC+0)`, `${intl.formatMessage({id: "end_time"})} (UTC+0)`]}
                                                                                        format={[customFormat, customFormat]}
                                                                                        popupClassName="tradingRatesPeriods"/>
                                                                                    {
                                                                                        !isNaN(periodError) && periodError === key ?
                                                                                            <p className="errorMsg"><FormattedMessage id='wrong_period'/></p> :""
                                                                                    }

                                                                                </div>

                                                                                <div className="selectedList">{

                                                                                    elemKey === key && periods[key] ? periods[key].map((item, idx) => (
                                                                                        <div key={idx}
                                                                                             className="dateItem">{`${item.start}-${item.end}`}
                                                                                            <div className="close" onClick={() => deletePeriod(item,key)}/>
                                                                                        </div>
                                                                                    )) : ""
                                                                                }
                                                                                </div>
                                                                            </div>
                                                                        </Form.Item>
                                                                    </div>
                                                                )
                                                            })}

                                                        </>
                                                    )}
                                                </Form.List>
                                            </div>
                                        </div>
                                    </div>
                                </Tabs.TabPane>
                            </Tabs>

                            <Form.Item className="MainModal--footer">
                                <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                    <FormattedMessage id='cancel'/>
                                </button>
                                <Button type="primary"
                                        htmlType="submit"
                                        style={{height: 36}}
                                        className={classNames("miniBtn", {submit: firstTabError || periodError})}
                                        disabled={firstTabError || periodError}
                                        loading={loading[`_edit_single_trading_rates${selectedForEdit}`]}
                                >
                                    <FormattedMessage id='save'/>
                                </Button>
                                <div className="error_box"><FormattedMessage id={"form_error"}/></div>
                            </Form.Item>
                        </Form>
                    </>


            }
        </Modal>)
}

function mapStateToProps(state) {
    return {
        singleTradingRate: state.singleTradingRateReducer,
        loading: state.loading,
    }
}

const mapDispatchToProps =
    {
        getSingleTradingRate,
        editSingleTradingRate,
    }

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditTradingRates));