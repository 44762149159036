import moment from "moment";

export const getFormattedForForm = (data) => {
    const initialData = {}
    Object.keys(data).forEach(key => {
        if (key === "types" || key === "statuses" || key === "predictions" ||  key === "assets") {
            if(!Array.isArray(data[key])) {
                initialData[key] = data[key].split(",")
            } else {
                initialData[key] = data[key]
            }
        } else if( typeof  data[key] === "object") {
            if(key.indexOf("date") > -1) {
                (!initialData[key]) && (initialData[key] = []);
                initialData[`${key}`][0] =  moment(data[key].start)
                initialData[`${key}`][1] =  moment(data[key].end)
            }else {
                initialData[`${key}Start`] = data[key].start
                initialData[`${key}End`] = data[key].end
            }
        }
        else {
            initialData[key] = data[key]
        }
    })
    return initialData
}

export const getDateForRequest = (date, formatted =false) => {
    if (date && date[0] && date[1]) {
        return {
            start: formatted ? moment(date[0]).utc().format() : moment(date[0].toString()),
            end: formatted ? moment(date[1]).utc().format() : moment(date[1].toString())
        }
    }
    return {start: "", end: ""}
}
export const getFormattedStateData = (data) => {
    return {
        clientId: data.clientId ? data.clientId : "",
        playerNameLike: data.playerNameLike ? data.playerNameLike : "",
        country: data.country ? data.country : "",
        citi: data.citi ? data.citi : "",
        statuses: data.statuses ? data.statuses.toString() : "",
        types: data.types ? data.types.toString() : "",
        predictions: data.predictions ? data.predictions.toString() : "",
        assets: data.assets ? data.assets.toString() : "",
        ip: data.ip ? data.ip : "",
        playerId: data.playerId ? data.playerId : "",
        betId: data.betId ? data.betId : "",
        transactionId: data.transactionId ? data.transactionId : "",
        session: data.session ? data.session : "",
        stakeRange: {start: data.stakeRangeStart || "", end: data.stakeRangeEnd || ""},
        amountRange: {start: data.amountRangeStart || "", end: data.amountRangeEnd || ""},
        winRange: {start: data.winRangeStart || "", end: data.winRangeEnd || ""},
        dateCreatedRange: getDateForRequest(data.dateCreatedRange),
        dateExpireRange: getDateForRequest(data.dateExpireRange)
    }
}