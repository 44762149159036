import React from "react";
import SortableContainer from "./SortableContainer";

const DraggableContainer = props => (
    <SortableContainer
        useDragHandle
        useWindowAsScrollContainer={false}
        helperClass="row-dragging"
        onSortEnd={props.onSortEnd}
        {...props}
    />
)

export default DraggableContainer;