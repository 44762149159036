import {
    SET_ACTIVE_BET_LIST,
    CLEAR_ACTIVE_BET_LIST
} from "@actions/actionTypes";
import initialStore from '../../initialStore';

export default function activeBetsListReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_ACTIVE_BET_LIST:
            return action.bets
        case CLEAR_ACTIVE_BET_LIST:
            return initialStore.emptyArr
        default:
            return state;
    }
}
