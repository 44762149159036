export const FETCH_FAILED = "FETCH_FAILED";

export const USER_LOGGING_REQUEST = "USER_LOGGING_REQUEST";
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_IS_END_SILENT = "SET_IS_END_SILENT";

export const REQUEST_PROVIDERS_ASSETS = "REQUEST_PROVIDERS_ASSETS"
export const SET_PROVIDERS_ASSETS = "SET_PROVIDERS_ASSETS"
export const SET_RATE_PROVIDER = "SET_RATE_PROVIDER"
export const SET_RATE_PROVIDER_SUCCESS = "SET_RATE_PROVIDER_SUCCESS"

export const SET_RESEND_FORGOT_PASSWORD = "SET_RESEND_FORGOT_PASSWORD";

/* Rates Groups */
//GET
export const REQUEST_RATES_GROUPS = "REQUEST_RATES_GROUPS";

//POST
export const REQUEST_ADD_RATES_GROUP = "REQUEST_ADD_RATES_GROUP";
export const REQUEST_SORT_RATES_GROUPS = "REQUEST_SORT_RATES_GROUPS";
export const REQUEST_RATES_GROUP_CONFIGS = "REQUEST_RATES_GROUP_CONFIGS";
export const REQUEST_IS_ENABLED_RATES_GROUP = "REQUEST_IS_ENABLED_RATES_GROUP";

//SET
export const SET_RATES_GROUPS = "SET_RATES_GROUPS";
export const SET_ADD_RATES_GROUP = "SET_ADD_RATES_GROUP";
export const SET_RATES_GROUP_CONFIGS = "SET_RATES_GROUP_CONFIGS";
export const SET_EDIT_RATES_GROUP = "SET_EDIT_RATES_GROUP";
export const SET_IS_ENABLED_RATES_GROUP = "SET_IS_ENABLED_RATES_GROUP";
export const SET_DELETE_RATES_GROUP = "SET_DELETE_RATES_GROUP"

//PUT
export const REQUEST_EDIT_RATES_GROUP = "REQUEST_EDIT_RATES_GROUP";

//DELETE
export const REQUEST_DELETE_RATES_GROUP = "REQUEST_DELETE_RATES_GROUP"

export const REQUEST_START_PATH = "REQUEST_START_PATH";
export const SET_START_PATH = "SET_START_PATH";

/* Tradings Rate */

//GET
export const REQUEST_TRADING_RATES = "REQUEST_TRADING_RATES";
export const SET_TRADING_RATES = "SET_TRADING_RATES";
export const REQUEST_ASSET_STATUS = "REQUEST_ASSET_STATUS";

export const REQUEST_SINGLE_TRADING_RATES = "REQUEST_SINGLE_TRADING_RATES";
export const SET_SINGLE_TRADING_RATES = "SET_SINGLE_TRADING_RATES";

//PUT 
export const REQUEST_EDIT_SINGLE_TRADING_RATES = "REQUEST_EDIT_SINGLE_TRADING_RATES";

export const REQUEST_IS_ENABLED_TRADING_RATE = "REQUEST_IS_ENABLED_TRADING_RATE";


//SET
export const SET_EDIT_SINGLE_TRADING_RATES = "SET_EDIT_SINGLE_TRADING_RATES"
export const SET_ASSET_STATUS = "SET_ASSET_STATUS"
export const SET_IS_ENABLED_TRADING_RATE = "SET_IS_ENABLED_TRADING_RATE";


// translations

// GET
export const REQUEST_LANG_LIST = "REQUEST_LANG_LIST";
export const REQUEST_TRANSLATIONS = "REQUEST_TRANSLATIONS";
export const REQUEST_ADD_TRANSLATIONS_ICON = "REQUEST_ADD_TRANSLATIONS_ICON"

// SET
export const SET_LANG_LIST = "SET_LANG_LIST";
export const SET_TRANSLATIONS = "SET_TRANSLATIONS";
export const SET_ICON_DELETED_CONDITON = "SET_ICON_DELETED_CONDITON"
export const SET_EDIT_TRANSLATIONS = "SET_EDIT_TRANSLATIONS"
export const RESET_TRANSLATION_UPDATED_STATUS = "RESET_TRANSLATION_UPDATED_STATUS"

//PUT

export const REQUEST_EDIT_TRANSLATIONS = "REQUEST_EDIT_TRANSLATIONS";
export const PUT_TRANSLATIONS_ICON = "PUT_TRANSLATIONS_ICON";

// DELETE

export const DELETE_TRANSLATE_ICON = "DELETE_TRANSLATE_ICON";


// settings

// PUT
export const REQUEST_ADD_MIN_MAX_DURATIONS = "REQUEST_ADD_MIN_MAX_DURATIONS"

//SET
export const SET_MIN_MAX_DURATIONS = "SET_MIN_MAX_DURATIONS"
export const SET_ADD_MIN_MAX_DURATIONS = "SET_ADD_MIN_MAX_DURATIONS"

//GET
export const REQUEST_MIN_MAX_DURATIONS = "REQUEST_MIN_MAX_DURATIONS"


// clients

//GET
export const REQUEST_CLIENT_LIST = "REQUEST_CLIENT_LIST"
export const REQUEST_SINGLE_CLIENT = "REQUEST_SINGLE_CLIENT"

//SET
export const SET_CLIENT_LIST = "SET_CLIENT_LIST"
export const SET_EDIT_CLIENT = "SET_EDIT_CLIENT"
export const SET_ADD_CLIENT = "SET_ADD_CLIENT"
export const SET_SINGLE_CLIENT = "SET_SINGLE_CLIENT"

//POST
export const REQUEST_ADD_CLIENT = "REQUEST_ADD_CLIENT"

//PUT
export  const REQUEST_EDIT_CLIENT = "REQUEST_EDIT_CLIENT"


//errors
export const  SET_ERROR_MSG = "SET_ERROR_MSG"
export const  RESET_ERROR_MSG = "RESET_ERROR_MSG"
export const CLEAR_RESPONSE_ERROR = "CLEAR_RESPONSE_ERROR"
export const CHANGE_RESPONSE_ERROR = "CHANGE_RESPONSE_ERROR"

//loading

export const CHANGE_LOADING = 'CHANGE_LOADING';


// bets

// players
export const REQUEST_PLAYERS_BY_IP = "REQUEST_PLAYERS_BY_IP"
export const SET_PLAYERS_BY_IP = "SET_PLAYERS_BY_IP"
export const CLEAR_PLAYERS_BY_IP = "CLEAR_PLAYERS_BY_IP"

//GET
export const REQUEST_BET_LIST = "REQUEST_BET_LIST"
export const REQUEST_BET_LIST_FILTER = "REQUEST_BET_LIST_FILTER"
export const REQUEST_BET_CSV_DOWNLOADER_URL = "REQUEST_BET_CSV_DOWNLOADER_URL"


//SET
export const SET_BET_LIST = "SET_BET_LIST"
export const SET_BET_LIST_FILTER = "SET_BET_LIST_FILTER"
export const SET_BET_CSV_DOWNLOADER_URL = "SET_BET_CSV_DOWNLOADER_URL"


export const REQUEST_CANCEL_BET_CSV_DOWNLOAD = "REQUEST_CANCEL_BET_CSV_DOWNLOAD"
export const SET_CANCEL_BET_CSV_DOWNLOAD = "SET_CANCEL_BET_CSV_DOWNLOAD"

// transactions

//GET
export const REQUEST_TRANSACTIONS_FILTER = "REQUEST_TRANSACTIONS_FILTER"
export const REQUEST_TRANSACTIONS_LIST = "REQUEST_TRANSACTIONS_LIST"
export const REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL = "REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL"

//SET
export const SET_TRANSACTIONS_FILTER = "SET_TRANSACTIONS_FILTER"
export const SET_TRANSACTIONS_LIST = "SET_TRANSACTIONS_LIST"
export const SET_TRANSACTIONS_CSV_DOWNLOADER_URL = "SET_TRANSACTIONS_CSV_DOWNLOADER_URL"

//PUT
export const REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD = "REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD"
export const SET_CANCEL_TRANSACTIONS_CSV_DOWNLOAD = "SET_CANCEL_TRANSACTIONS_CSV_DOWNLOAD"

// activeBets

//GET
export const REQUEST_ACTIVE_BET_LIST = "REQUEST_ACTIVE_BET_LIST"

//SET
export const SET_ACTIVE_BET_LIST = "SET_ACTIVE_BET_LIST"
export const CLEAR_ACTIVE_BET_LIST = "CLEAR_ACTIVE_BET_LIST"

// csv
export const SET_ALLOW_CSV_CANCEL = "SET_ALLOW_CSV_CANCEL"
