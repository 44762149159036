import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    addClient,
    editClient
} from "@actions/clientsActions";
import {
    REQUEST_ADD_CLIENT,
    REQUEST_EDIT_CLIENT
} from "@actions/actionTypes";

import {Modal, Form, Input, Row, Col, Switch, Button, Radio, Tabs, Tooltip, Space, Select } from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import classNames from "classnames";
import {DeleteFilled, PlusOutlined} from '@ant-design/icons';
import USDIcon from "@assets/img/currency/USD.svg";
import GBPIcon from "@assets/img/currency/GBP.svg";
import INRIcon from "@assets/img/currency/INR.svg";
import HKDIcon from "@assets/img/currency/HKD.svg";
import AEDIcon from "@assets/img/currency/AED.svg";
import ModalLoader from "@components/Loaders/modalLoader";
import {GameTemplateCurrency} from "@constants/Enums";
const currencyIcons = {
    USD: USDIcon,
    GBP: GBPIcon,
    INR: INRIcon,
    HKD: HKDIcon,
    AED: AEDIcon
}
const defaultCurrencies =  [
    {
        "minStake": undefined,
        "currency": undefined,
        "maxStake": undefined,
        "favoriteAmounts": []
    }
]

function ClientControlPopup({handleCancel, show, intl, addClient, client, editClient, loading}) {
    const [form] = Form.useForm();
    const [tabMode, setTabMode] = useState("1");
    const [isFirstOpen, setIsFirstOpen] = useState(!client?.id);
    const [firstTabError, setFirstTabError] = useState(false);
    const [secondTabValid, setSecondTabValid] = useState(false);
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    useEffect(() => {
        if(client.id) {
            const formBody = JSON.parse(JSON.stringify(client))

            if (!(client.currencies && client.currencies[0])) {
                formBody.currencies = [...defaultCurrencies]
            }

            form.setFieldsValue(formBody)
        }else {
            form.setFieldsValue({"isVisibleBalance" : true, currencies: defaultCurrencies})
        }
    }, [client])

    const onFinish = (data) => {
        if (client.id) {
            editClient({...data, id:client.id, name: client.name, activeBetsPerAsset: data.activeBetsPerAsset || 100})
        } else {
            addClient(data)
        }
    }

    useEffect(() => {
        if (client.id) {
            subscribeForError(REQUEST_EDIT_CLIENT, () => {
                handleCancel();
            });
        } else {
            subscribeForError(REQUEST_ADD_CLIENT, () => {
                handleCancel();
            });
        }
        return () => {
            unSubscribeForError(REQUEST_ADD_CLIENT);
            unSubscribeForError(REQUEST_EDIT_CLIENT);
        }
    }, [])

    const handleModeChange = (e) => {
        const mode = e.target.value;
        if (mode === "2") {
            setIsFirstOpen(false)
        }
        setTabMode(mode);
    }

    const formValueChange = () => {
        let firstTabValid = false;
        let secondTabValid = false;
        setFirstTabError(false);
        setSecondTabValid(false);


        form.getFieldsError().some((field) => {
            if (field.name.includes("currencies")) {
                secondTabValid = field.errors.length > 0
                return field.errors.length > 0
            } else {
                firstTabValid = field.errors.length > 0
                return field.errors.length > 0
            }
        })
        setFirstTabError(firstTabValid)
        setSecondTabValid(secondTabValid)
    }

    const validStackValue = (name) => {
        let formValue = form.getFieldsValue()
        let value = formValue[name[0]][name[1]][name[2]]
        if (value[value.length - 1 ] === ".") {
            formValue[name[0]][name[1]][name[2]] = value.substring(0, value.length - 1);
        }
        form.setFieldsValue(formValue)
    }

    const enterValidDecimalNumber = (value, name) => {
        const numberRegExp = new RegExp(/^([0-9]+(\.?[0-9]?[0-9]?)?)$/g);
        let formValue = form.getFieldsValue()
        if (numberRegExp.test(value)) {
            formValue[name[0]][name[1]][name[2]] = value
            const afterDot = value.split(".")[0]
            const beforeDot = value.split(".")[1]
            if (/\b(0)+[1-9]\d*/g.test(afterDot)) {
                const val = afterDot.replace(/\b(0)+/g, '')
                formValue[name[0]][name[1]][name[2]] = val
            } else {
                if (/\b(0)+[.]\d*/g.test(value)) {
                    const val = afterDot.replace(/\b(0)+/g, '') + "." + beforeDot;
                    formValue[name[0]][name[1]][name[2]] = "0" + val
                }
            }
        } else {
            formValue[name[0]][name[1]][name[2]] = value.substring(0, value.length - 1)
        }

        form.setFieldsValue(formValue)
    }

    const changeAmounts = (favoriteAmounts, index) => {
        const minStack = form.getFieldValue(["currencies", index, "minStake"]);
        const maxStack = form.getFieldValue(["currencies", index, "maxStake"]);
        if (favoriteAmounts) {
            let currentValue =  favoriteAmounts[favoriteAmounts.length - 1];
            if ( typeof currentValue === "string") {
                currentValue =  currentValue.replace(",",".")
                const numberRegExp = new RegExp( /^\d+(\.\d{1,2})?$/)
                if(!numberRegExp.test(currentValue) ||  favoriteAmounts.indexOf(+currentValue) > -1) {
                    const index = favoriteAmounts.indexOf(favoriteAmounts[favoriteAmounts.length - 1])
                    favoriteAmounts.splice(index,1)
                }else if ((+currentValue < +minStack) || (+currentValue > +maxStack)) {
                    const index = favoriteAmounts.indexOf(favoriteAmounts[favoriteAmounts.length - 1])
                    favoriteAmounts.splice(index,1)
                } else {
                    favoriteAmounts[favoriteAmounts.length - 1 > 0 ? favoriteAmounts.length - 1 : 0 ] = +currentValue
                }

                form.setFieldsValue([{favoriteAmounts: favoriteAmounts.sort((a, b) => a - b)}])
            }
        }

        stackChange(["currencies", index, "favoriteAmounts"])
    }

    const stackChange = (name) => {
        const value = form.getFieldValue(name)
        if (value.length) {
            form.validateFields([name])
        }
    }

    const dependsStack = (name) => {
        const value = form.getFieldValue(name)
        if (value) {
            form.validateFields([name])
        }
    }

    return (
        <>
            {

                <Modal
                    title={show === "add" ? <FormattedMessage id='add_client'/> :
                        <FormattedMessage id='edit_client'/>}
                    centered
                    className={classNames(`formModal`, {loading: loading._single_client})}
                    visible={show}
                    width={424}
                    onCancel={() => handleCancel()}
                    footer={false}>
                    {
                        loading._single_client ?
                            <ModalLoader tabs={true}/> :
                            <>
                                <div className="tabMode centeredBox">
                                    <Radio.Group onChange={handleModeChange} value={tabMode}
                                                 className={classNames({invalid: firstTabError || secondTabValid})}>
                                        <Radio.Button className={classNames("centeredBox", {invalid: firstTabError})} value="1"><FormattedMessage
                                            id={'details'}/></Radio.Button>
                                        <Radio.Button className={classNames("centeredBox", {invalid: secondTabValid})}
                                                      value="2"><FormattedMessage
                                            id={'currency'}/></Radio.Button>
                                    </Radio.Group>
                                </div>
                                <Form
                                    layout="vertical"
                                    form={form}
                                    name="trading-form"
                                    onFinish={onFinish}
                                    scrollToFirstError
                                    onFieldsChange={formValueChange}
                                    validateTrigger="onBlur"
                                >
                                    <Tabs activeKey={tabMode} className="overflowContent">
                                        <Tabs.TabPane key="1">
                                            <Row className="MainModal">
                                                <Col span={24} className="MainModal--parts">
                                                    <div className="MainModal--parts--content">
                                                        {client.id ?
                                                            <Form.Item label={<FormattedMessage id='name'/>}>
                                                                {client.name}
                                                            </Form.Item> :
                                                            <Form.Item label={<FormattedMessage id='name'/>}
                                                                       rules={[
                                                                           {
                                                                               required: true,
                                                                               message: <FormattedMessage
                                                                                   id='name_validator'/>
                                                                           },
                                                                           ({getFieldValue}) => ({
                                                                               validator(_, value) {
                                                                                   const nameRegExp = new RegExp(/^[\w|_| ]+$/)

                                                                                   if (value && !(value.length >= 3 && value.length <= 256)) {
                                                                                       return Promise.reject(intl.formatMessage({id: `name_valid`}))
                                                                                   }

                                                                                   if (value && !nameRegExp.test(value)) {
                                                                                       return Promise.reject(intl.formatMessage({id: `name_must_not_contain_special_characters`}))
                                                                                   }

                                                                                   return Promise.resolve();
                                                                               },
                                                                           })
                                                                       ]}
                                                                       name={"name"}>
                                                                <Input autoComplete="off"
                                                                       placeholder={intl.formatMessage({id: "type_name"})}/>
                                                            </Form.Item>

                                                        }
                                                        <Form.Item label={<FormattedMessage id='secret'/>}
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage
                                                                               id='secret_validator'/>
                                                                       },
                                                                       ({getFieldValue}) => ({
                                                                           validator(_, value) {

                                                                               if (value && value.length > 255) {
                                                                                   return Promise.reject(intl.formatMessage({id: `client_secret_valid`}))

                                                                               }
                                                                               return Promise.resolve();
                                                                           },
                                                                       })
                                                                   ]}
                                                                   name={"secret"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_secret"})}/>
                                                        </Form.Item>
                                                        <Form.Item label={<FormattedMessage id='bet_url'/>}
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage
                                                                               id='bet_url_validator'/>
                                                                       },
                                                                       ({getFieldValue}) => ({
                                                                           validator(_, value) {
                                                                               const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                               if (value && !urlPattern.test(value)) {
                                                                                   return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                               }
                                                                               return Promise.resolve();
                                                                           },
                                                                       })
                                                                   ]}
                                                                   name={"betUrl"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_bet_url"})}/>
                                                        </Form.Item>
                                                        <Form.Item label={<FormattedMessage id='result_url'/>}
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage
                                                                               id='result_url_validator'/>
                                                                       },
                                                                       ({getFieldValue}) => ({
                                                                           validator(_, value) {
                                                                               const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                               if (value && !urlPattern.test(value)) {
                                                                                   return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                               }
                                                                               return Promise.resolve();
                                                                           },
                                                                       })
                                                                   ]}
                                                                   name={"resultUrl"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_result_url"})}/>
                                                        </Form.Item>
                                                        <Form.Item label={<FormattedMessage id='rollback_url'/>}
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage
                                                                               id='rollback_url_validator'/>
                                                                       },
                                                                       ({getFieldValue}) => ({
                                                                           validator(_, value) {
                                                                               const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                               if (value && !urlPattern.test(value)) {
                                                                                   return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                               }
                                                                               return Promise.resolve();
                                                                           },
                                                                       })
                                                                   ]}
                                                                   name={"refundUrl"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_rollback_url"})}/>
                                                        </Form.Item>
                                                        <Form.Item label={<FormattedMessage id='balance_url'/>}
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage
                                                                               id='balance_url_validator'/>
                                                                       },
                                                                       ({getFieldValue}) => ({
                                                                           validator(_, value) {
                                                                               const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                               if (value && !urlPattern.test(value)) {
                                                                                   return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                               }
                                                                               return Promise.resolve();
                                                                           },
                                                                       })
                                                                   ]}
                                                                   name={"balanceUrl"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_balance_url"})}/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={<FormattedMessage id='active_bets_per_asset'/>}
                                                            rules={[
                                                                ({getFieldValue}) => ({
                                                                    validator(_, value) {
                                                                        const countPattern = new RegExp(/^(?!(0))\d*[1-9]\d*$/)
                                                                        if (value && !countPattern.test(value)) {
                                                                            return Promise.reject(intl.formatMessage({id: `entered_count_is_not_valid`}));
                                                                        }
                                                                        return Promise.resolve();
                                                                    },
                                                                })
                                                            ]}
                                                            name={"activeBetsPerAsset"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_count"})}/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={<FormattedMessage id='show_balance_preview'/>}
                                                            className="formHorizontal"
                                                            name="isVisibleBalance"
                                                            valuePropName="checked">
                                                            <Switch className="radioSwitch"/>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key="2" forceRender={true}>
                                            <Form.List name="currencies">
                                                {(fields, {add, remove}) => (
                                                    <>
                                                        {fields.map(({key, name, fieldKey, ...restField}) =>
                                                            <Space key={key} className="defaultGames" size={0}>
                                                                <Form.Item
                                                                    label={<FormattedMessage id='currency'/>}
                                                                    {...restField}
                                                                    name={[name, 'currency']}
                                                                    fieldKey={[fieldKey, 'currency']}
                                                                    rules={
                                                                        [
                                                                            {
                                                                                required: true,
                                                                                message: <FormattedMessage
                                                                                    id='currency_is_required'/>
                                                                            }
                                                                        ]
                                                                    }
                                                                >
                                                                    <Select placeholder={intl.formatMessage({id: "select"})}
                                                                            optionLabelProp="label"
                                                                            dropdownMatchSelectWidth={false}
                                                                            className="gameTemplateCurrencySelect"
                                                                            getPopupContainer={triggerNode => triggerNode.parentElement}>
                                                                        {
                                                                            GameTemplateCurrency.map(currency => (
                                                                                <Select.Option value={currency}
                                                                                               key={currency}
                                                                                               label={
                                                                                                   <>
                                                                                                       <img
                                                                                                           src={currencyIcons[currency]}
                                                                                                           className="icon"/>
                                                                                                       <FormattedMessage
                                                                                                           id={`${currency}_short`}/>
                                                                                                   </>
                                                                                               }>
                                                                                    <img
                                                                                        src={currencyIcons[currency]}
                                                                                        className="icon"/>
                                                                                    <FormattedMessage
                                                                                        id={currency}/>
                                                                                </Select.Option>
                                                                            ))
                                                                        }

                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label={<FormattedMessage id='min_stake'/> }
                                                                    name={[name, 'minStake']}
                                                                    fieldKey={[fieldKey, 'minStake']}
                                                                    onBlur={() => {
                                                                        validStackValue(["currencies", name, "minStake"])
                                                                        stackChange(["currencies", name, "favoriteAmounts"])
                                                                        dependsStack(["currencies", name, 'maxStake'])
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage
                                                                                id='min_stack_required'/>
                                                                        },
                                                                        ({getFieldValue}) => ({
                                                                            validator(_, value) {

                                                                                if (value && value <= 0) {
                                                                                    return Promise.reject(intl.formatMessage({id: `amount_validation`}));

                                                                                }

                                                                                if (!value || Number(getFieldValue(["currencies", name, 'maxStake'])) > Number(value) || !getFieldValue(["currencies", name, 'maxStake'])) {
                                                                                    return Promise.resolve();
                                                                                }

                                                                                return Promise.reject(intl.formatMessage({id: `the_min_value_cant_be_larger_the_max`}));
                                                                            },
                                                                        })
                                                                    ]}
                                                                >
                                                                    <Input autoComplete="off"
                                                                           onChange={({target}) => enterValidDecimalNumber(target.value, ["currencies", name, 'minStake'])}
                                                                           placeholder={intl.formatMessage({id: `type_stake`})}/>
                                                                </Form.Item>
                                                                <div className="separator"/>
                                                                <Form.Item
                                                                    label={<FormattedMessage id='max_stake'/>}
                                                                    {...restField}
                                                                    name={[name, 'maxStake']}
                                                                    fieldKey={[fieldKey, 'maxStake']}
                                                                    onBlur={() => {
                                                                        validStackValue(["currencies", name, "maxStake"])
                                                                        stackChange(["currencies", name, "favoriteAmounts"])
                                                                        dependsStack(["currencies", name, 'minStake'])
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage
                                                                                id='max_stack_required'/>
                                                                        },
                                                                        ({getFieldValue}) => ({
                                                                            validator(_, value) {
                                                                                if (value && value > 1000000000) {
                                                                                    return Promise.reject(intl.formatMessage({id: `amount_validation`}));

                                                                                }

                                                                                if (!value || Number(getFieldValue(["currencies", name, 'minStake'])) < Number(value) || !getFieldValue(["currencies", name, 'minStake'])) {
                                                                                    return Promise.resolve();
                                                                                }

                                                                                return Promise.reject(intl.formatMessage({id: `the_max_value_cant_be_smaller_the_min`}));
                                                                            },
                                                                        })
                                                                    ]}>
                                                                    <Input autoComplete="off"
                                                                           onChange={({target}) =>  enterValidDecimalNumber(target.value, ["currencies", name, 'maxStake'])}
                                                                           placeholder={intl.formatMessage({id: `type_stake`})}/>
                                                                </Form.Item>

                                                                <DeleteFilled  className={classNames("iconBox ", {disabled: fields.length === 1})}
                                                                               onClick={() => {
                                                                                   if(fields.length !== 1) {
                                                                                       remove(name)
                                                                                       setTimeout(() =>formValueChange(), 10)
                                                                                   }
                                                                               }}
                                                                               disabled={fields.length === 1}/>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label={<FormattedMessage id='amount'/> }
                                                                    name={[name, 'favoriteAmounts']}
                                                                    fieldKey={[fieldKey, 'favoriteAmounts']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage id='amount_stack_required'/>
                                                                        },
                                                                        ({getFieldValue}) => ({
                                                                            validator(_, value) {
                                                                                const minStack = getFieldValue(["currencies", name, 'minStake']);
                                                                                const maxStack = getFieldValue(["currencies", name, 'maxStake']);
                                                                                if (value) {
                                                                                    const currentValue = value[value.length -1]
                                                                                    const numberRegExp = new RegExp( /^\d+(\.\d{1,2})?$/)
                                                                                    let stackError =false
                                                                                    if (value && value.length > 1 && !numberRegExp.test(currentValue))
                                                                                    {
                                                                                        return Promise.reject(intl.formatMessage({id: `amount_is_not_valid`}))

                                                                                    }

                                                                                    value.map(item => {
                                                                                        if (item < parseFloat(minStack) || item > parseFloat(maxStack)) stackError = true;
                                                                                    })

                                                                                    if (stackError) {
                                                                                        return Promise.reject(intl.formatMessage({id: `please_check_min_max_amount_settings`}))
                                                                                    }


                                                                                    return Promise.resolve();
                                                                                } else {
                                                                                    return Promise.reject()

                                                                                }

                                                                            },
                                                                        })
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        placeholder={intl.formatMessage({id: "type_amount"})}
                                                                        mode="tags"
                                                                        style={{ width: '100%' }}
                                                                        open={false}
                                                                        onChange={(value) => changeAmounts(value, name)}
                                                                    />
                                                                </Form.Item>
                                                                <hr className="divider"/>
                                                            </Space>
                                                        )}
                                                        <Form.Item className="add-button">
                                                            <Tooltip placement="left" title={<FormattedMessage id="add_currency"/>}>
                                                                <PlusOutlined
                                                                    onClick={() => add()}/>
                                                            </Tooltip>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Tabs.TabPane>
                                    </Tabs>
                                    <Form.Item className="MainModal--footer">
                                        <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                            <FormattedMessage id='cancel'/>
                                        </button>
                                        <Button type="primary"
                                                htmlType="submit"
                                                style={{height: 36}}
                                                className="miniBtn"
                                                loading={loading._add_client || loading._edit_client}
                                        >
                                            <FormattedMessage id='save'/>
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>
                    }

                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading
    }
}

const mapDispatchToProps = {
    addClient,
    editClient
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ClientControlPopup));