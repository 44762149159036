import initialStore from "../../initialStore";
import {
    REQUEST_TRADING_RATES,
    SET_TRADING_RATES,
    SET_EDIT_SINGLE_TRADING_RATES,
    SET_IS_ENABLED_TRADING_RATE
} from "@actions/actionTypes";

export default function tradingRatesReducer(state = {tradingRates: initialStore.tradingRates}, action) {
    switch (action.type) {
        case REQUEST_TRADING_RATES:
            return {tradingRates: initialStore.tradingRates}
        case SET_TRADING_RATES:
            return {tradingRates: action.ratesGroups}
        case SET_IS_ENABLED_TRADING_RATE:
            let newArr = state.tradingRates.items.map(t => {
                if(t.id === action.data.id) {
                    return action.data;

                }
                return t;
            });
            return {tradingRates: {total: state.tradingRates.total, items: newArr} }
        case SET_EDIT_SINGLE_TRADING_RATES:
            let newRate = state.tradingRates.items.map(t => {
                if(t.id === action.data.id) {
                    return action.data;
                }
                return t;
            });
            return {tradingRates: {total: state.tradingRates.total, items: newRate} }
        default:
            return state;
    }
}
