export  const secondsToHms = (d) => {
    d = Number(d);
    const h = d / 3600;
    const m = d / 60;
    const s = d ;

    if(h >= 1) {
        return { date: h % 1 !== 0 ? m % 1 !== 0 ? s : m : h, charset: h % 1 !== 0 ? m % 1 !== 0 ? "s" : "m" : "h" }
    } else if(m >= 1) {
        return { date: m % 1 !== 0 ? s : m, charset: m % 1 !== 0 ? "s" : "m" }
    }else {
        return { date: s, charset:"s" }
    }
}

export  const HmsToseconds = (d) => {
    if(d.search("h") > 0){
        return Math.floor(parseInt(d) * 60 * 60);
    }else if(d.search("m") > 0){
        return Math.floor(parseInt(d)  * 60);
    } else {
        return parseInt(d) ;
    }
}

export const sortDurationsArray = (a, b) => {
    let aValue = HmsToseconds(a[`durations${a.elemIndex}`] + ''+ a[`timeSelector${a.elemIndex}`]) || 0;
    let bValue = HmsToseconds(b[`durations${b.elemIndex}`] + '' + b[`timeSelector${b.elemIndex}`]) || 0;
    if(aValue === 0) {
        return 1;
    }
    if(bValue === 0) {
        return -1;
    }
    if (aValue < bValue) {
        return -1;
    }
    if (bValue > bValue) {
        return 1;
    }
}


export const customFormat = value => {
    if (value.isValid()) {
        return `${value.format("HH:mm:ss")} (UTC+0)`;
    } else {
        return `00:00:00 (UTC+0)`;
    }
}

export const timestampToParts = (timestamp, intl) => {
    const d = Math.floor(timestamp / 86400)
    const h = Math.floor(timestamp / 3600);
    const m = Math.floor(timestamp % 3600 / 60);
    const s = Math.floor(timestamp % 3600 % 60);

    return `${d ? d + intl.formatMessage({id: "dayShort"}) + " " : ""} ${h && h != '00' ? h + intl.formatMessage({id: "hourShort"}) + " " : ""} ${m ? m + intl.formatMessage({id: "minuteShort"}) : ""} ${s ? s + intl.formatMessage({id: "secoundShort"}) : ""}`

}

export const sortConfigs = function (a, b) {
    if (a.index === b.index) {
        return 0;
    }
    else if (a.index === 0) {
        return 1;
    }
    else if (b.index === 0) {
        return -1;
    }
    else  {
        return a.index < b.index ? -1 : 1;
    }

};