import {getBetListFilter, getBetsCsvLink, cancelBetCsvDownload} from "@actions/betsActions";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Button, DatePicker, Form, Input, Select} from "antd";
import {GamePrediction, BetType} from "@constants/Enums";
import React, {useEffect, useState} from "react";
import {getFormattedForForm, getFormattedStateData} from "@services/betsServices";
import {getFormattedUrlObject, getUrlString, getUrlValue} from "../../utils/CommonUtils";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import predictionUp from "@assets/img/icon/up.svg";
import predictionDown from "@assets/img/icon/down.svg";
import predictionAll from "@assets/img/icon/all.svg";
import moment from "moment-timezone";
import CsvDownloader from "@components/CSVDownloader/CsvDownloader";

const predictionIcons = [predictionAll, predictionUp, predictionDown]


function BetsFilter({filter, intl , limit, setPage, showFilter, getBetListFilter, selectedPlayer, getBetsCsvLink, loading, cancelBetCsvDownload, allowCancel}) {
    let history = useHistory()
    const [form] = Form.useForm();
    const [clientSelect, setClientSelect] = useState([]);
    const [statusSelect, setStatusSelect] = useState([]);
    const [assetSelect, setAssetSelect] = useState([])
    const [predictionSelect, setPredictionSelect] = useState([]);


    useEffect(() => { getBetListFilter() }, []);

    useEffect(() => {
        if (Object.keys(filter).length) {
            setClientSelect(filter.clientSelect);
            setPredictionSelect(filter.predictionSelect);
            setStatusSelect(filter.statusSelect);
            const asset =  JSON.parse(JSON.stringify(filter.assets || []));
            setAssetSelect(asset.sort(sortAssets));
        }
    }, [filter])

    useEffect(() => {
        return history.listen((location) => {
            const loc = history && history.location && history.location.search
            const urlObj = getUrlValue(loc);

            let initialForm = getFormattedUrlObject(urlObj)
            if(selectedPlayer) {
                initialForm["playerNameLike"]=selectedPlayer
            }
            form.resetFields()
            form.setFieldsValue(getFormattedForForm(initialForm))
        })
    },[history])

    const finishFilter = (data) => {
        const queryObj = {...getFormattedStateData(data), page: 1, limit}

        if (data.dateCreatedRange && data.dateCreatedRange.length) {
            queryObj["dateCreatedRangeStart"] =  data.dateCreatedRange[0].toString()
            queryObj["dateCreatedRangeEnd"] =  data.dateCreatedRange[1].toString()
        }
        delete queryObj["dateCreatedRange"]
        if (data.dateExpireRange && data.dateExpireRange.length) {
            queryObj["dateExpireRangeStart"] =  data.dateExpireRange[0].toString()
            queryObj["dateExpireRangeEnd"] =  data.dateExpireRange[1].toString()
        }
        delete queryObj["dateExpireRange"]

        const {params} = getUrlString(queryObj)
        setPage(1)

        history.push({search: params.toString()})
    }

    const resetFilter = () => {
        let initialForm = {}
        const initialObj = {start: "", end: ""}


        initialForm = {
            clientId:"",
            statuses: [],
            predictions: [],
            playerNameLike: "",
            assets: [],
            ip: "",
            dateCreatedRange: initialObj,
            dateExpireRange: initialObj,
            stakeRangeStart: "",
            stakeRangeEnd: "",
            winRangeStart: "",
            winRangeEnd: "",
            session: "",
        }

        form.setFieldsValue(initialForm)
    }


    const sortAssets = (a, b) => {
        if(a.key < b.key) { return -1; }
        if(a.key > b.key) { return 1; }
        return 0;
    }

    const exportCVS = () => {
        if (loading._bet_csv_downloader_url === undefined || !loading._bet_csv_downloader_url) {
            getBetsCsvLink(form.getFieldsValue())
        }
    }


    return (
        <div className="searchBar csv">
            <Form
                form={form}
                onFinish={finishFilter}
                layout="vertical"
                validateTrigger="onBlur">
                <div className={classNames("searchBar--row",{show: showFilter})}>
                    <div className="searchBar--col">
                        <Form.Item
                            label={<FormattedMessage id={"client"}/>} name="clientId">
                            <Select allowClear
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                            >
                                {clientSelect.map(client => (
                                    <Select.Option key={client.id} value={client.id}>{client.name}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="playerNameLike"
                            label={<FormattedMessage id={"player_name"}/>}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_player_name"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"bet_date"}/>} name="dateCreatedRange">
                            <DatePicker.RangePicker  getPopupContainer={triggerNode => triggerNode.parentElement} showTime/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"bet_amount"}/>}>
                            <Form.Item name="stakeRangeStart" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[+-]?\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_from_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "from"})}/>
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="stakeRangeEnd" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[+-]?\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_to_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "to"})}/>
                            </Form.Item>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"status"}/>} name="statuses">
                            <Select mode="multiple"
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    optionLabelProp="label" showArrow showSearch={false}>
                                {statusSelect.map(status => (
                                    <Select.Option key={status} value={""+status}
                                                   label={<FormattedMessage id={BetType[status]}/>}>
                                        <FormattedMessage id={BetType[status]}/>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="session"
                            label={<FormattedMessage id={"session"}/>} required={false}
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
                                        if ((value && value.length > 1) && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `value_must_be_GUID`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_session"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"prediction"}/>} name="predictions">
                            <Select mode="multiple" optionLabelProp="label"
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    showArrow showSearch={false} dropdownClassName="predictionDropDown">
                                {predictionSelect.map(prediction => (
                                    <Select.Option key={prediction} value={""+prediction}
                                                   getPopupContainer={triggerNode => triggerNode.parentElement}
                                                   label={<FormattedMessage id={GamePrediction[prediction]}/>}>
                                        <img src={predictionIcons[prediction]} className="icon" alt=""/>
                                        <FormattedMessage id={GamePrediction[prediction]}/>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"profit_amount"}/>}>
                            <Form.Item name="winRangeStart" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[+-]?\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_from_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "from"})}/>
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="winRangeEnd" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[+-]?\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_to_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "to"})}/>
                            </Form.Item>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"result_date"}/>}
                                   name="dateExpireRange">
                            <DatePicker.RangePicker  getPopupContainer={triggerNode => triggerNode.parentElement} showTime/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"assets"}/>} name="assets">
                            <Select mode="multiple"
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    optionLabelProp="label" showArrow showSearch={true}>
                                {assetSelect.map(asset => (
                                    <Select.Option key={asset.key} value={asset.key}
                                                   label={asset.key}>
                                        {asset.key}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"ips"}/>} name="ip" required={false}
                                   rules={[
                                       ({getFieldValue}) => ({
                                           validator(_, value) {
                                               const ipRegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g.test(value)
                                               if ((value && value.length > 1) && !ipRegExp )
                                               {
                                                   return Promise.reject(intl.formatMessage({id: `ip_is_not_valid`}))

                                               }
                                               return Promise.resolve();
                                           },
                                       })
                                   ]}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "ex._xxx.xxx.xxx.xxx"})} />
                        </Form.Item>
                    </div>
                    <div className="searchBar--col btnBox">
                        <div className="filterPart">
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className="miniBtn small"
                                    loading={loading._bet_list}
                            >
                                <FormattedMessage id='search'/>
                            </Button>
                            <button type="button" className="miniBtn default small" onClick={resetFilter}>
                                <FormattedMessage id='clear'/>
                            </button>
                        </div>
                       <div className="exportPart">
                           <CsvDownloader exportCVS={exportCVS}
                                          downloadLoading={loading._bet_csv_downloader_url}
                                          cancelLoading={loading._cancel_bet_csv_download}
                                          onCancel={cancelBetCsvDownload}
                                          allowCancel={allowCancel}/>
                       </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        filter: state.betsListFilter,
        loading: state.loading,
        allowCancel: state.csvAllowCancel.allowBets
    }
}

const mapDispatchToProps = {
    getBetListFilter,
    getBetsCsvLink,
    cancelBetCsvDownload
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BetsFilter));