import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
    REQUEST_PROVIDERS_ASSETS,
    SET_PROVIDERS_ASSETS,
    SET_RATE_PROVIDER,
    SET_RATE_PROVIDER_SUCCESS,
    SET_ERROR_MSG
} from "@actions/actionTypes";
import {
    GetResource,
    DeleteResource,
    PutResource,
    PostResource
} from '../../utils/HttpUtil';
import {onError, safe} from "./handlerError";

function* fetchProvidersAssets({page, limit, keyLike, sortDirDesc}) {
    const search = keyLike ? encodeURIComponent(keyLike) : ""; 
    const providersAssets = yield call(GetResource, `/rate/provider?page=${page}&limit=${limit}&sortDirDesc=${sortDirDesc}${keyLike? '&key=' + search: ''}`);
    yield put({type: SET_PROVIDERS_ASSETS, providersAssets});
}

function* setRateProvider({id, key}) {
    const provider = yield call(PostResource, `/rate/provider/${id}/active`);
    yield put({type: SET_RATE_PROVIDER_SUCCESS, provider, key});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
   
}

function* providersAssetsSaga() {
    yield takeLatest(REQUEST_PROVIDERS_ASSETS, safe(onError, fetchProvidersAssets));
    yield takeLatest(SET_RATE_PROVIDER, safe(onError, setRateProvider));
}
 
export default providersAssetsSaga;