import {sortableContainer} from "react-sortable-hoc";
import Infinite from "react-infinite";
import React from "react";
import SortableItem from "@components/ControlRatesGroups/SortableItem";

const SortableContainer = sortableContainer(({items, changeConfigs}) => {
    return ( <Infinite
        containerHeight={520}
        elementHeight={items.map(() => 40)} className="draggingList">
        {items.map((value, index) => (
            <SortableItem key={value.id} index={index}  value={value} height={40} changeConfigs={changeConfigs}/>
        ))}
    </Infinite>);
});

export default SortableContainer