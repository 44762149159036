import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
    REQUEST_RATES_GROUPS,
    SET_RATES_GROUPS,
    REQUEST_DELETE_RATES_GROUP,
    REQUEST_RATES_GROUP_CONFIGS,
    SET_RATES_GROUP_CONFIGS,
    REQUEST_EDIT_RATES_GROUP,
    REQUEST_ADD_RATES_GROUP,
    REQUEST_SORT_RATES_GROUPS,
    SET_EDIT_RATES_GROUP,
    SET_ERROR_MSG,
    REQUEST_IS_ENABLED_RATES_GROUP,
    SET_IS_ENABLED_RATES_GROUP,
    SET_DELETE_RATES_GROUP,
    SET_ADD_RATES_GROUP
} from "@actions/actionTypes";
import {
    GetResource,
    DeleteResource,
    PutResource,
    PostResource
} from '../../utils/HttpUtil';
import {onError, safe} from "./handlerError";
import {DefaultRatesGroupId} from "@constants/Enums";
import {sortConfigs} from "@services/TradingRatesService";

let pageSaga = 1, limitSaga = 10;

function* fetchRatesGroups({currentPage, limitPage}) {
    currentPage && (pageSaga = currentPage);
    limitPage && (limitSaga = limitPage);
    const ratesGroups = yield call(GetResource, `/rate/groups?page=${pageSaga}&limit=${limitSaga}`);
    yield put({ type: SET_RATES_GROUPS, ratesGroups });
}

function* deleteRatesGroups({id}) {
      yield call(DeleteResource, `/rate/groups/${id}`);
      yield put({
          type: REQUEST_RATES_GROUPS,
          currentPage: pageSaga,
          limitPage: limitSaga
      });
    yield put({type: SET_DELETE_RATES_GROUP})
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}

function* fetchRatesGroupsConfigs({data}) {
    let request = [
        call(GetResource, `/rate/groups/${data.id ? data.id : DefaultRatesGroupId}/configs`),
    ]
    if (data.id) {
        request.push(call(GetResource, `/rate/groups/${data.id}`))
    }

    const [ratesGroupsConfigs, ratesGroup] = yield all(request);
    const sortItem =ratesGroupsConfigs.items.sort(sortConfigs)
    const response = {
        ratesGroupsConfigs: sortItem,
        id: data.id,
        ratesGroup: ratesGroup || []
    };

    yield put({ type: SET_RATES_GROUP_CONFIGS , data: response});
}

function* editRatesGroups({data}) {
    const ratesGroup = yield call(PutResource, `/rate/groups/${data.id}/all`,data.body);
    yield put({
        type: SET_EDIT_RATES_GROUP,
        data: {...ratesGroup.data}
    });
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}


function* addRatesGroups({data}) {
   yield call(PostResource, `/rate/groups/`,data.body);
   yield put({
       type: REQUEST_RATES_GROUPS,
       currentPage: pageSaga,
       limitPage: limitSaga
   });
    yield put({type: SET_ADD_RATES_GROUP, data: {id: data.id}})
   yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}

function* sortRatesGroups({body}) {
    yield call(PostResource, `/rate/groups/sort`,body);
}

function* putIsEnableRatesGroup({data}) {
    const ratesGroup = yield call(PutResource, `/rate/groups/${data.id}`, {isEnabled: data.isEnabled});
    yield put({type: SET_IS_ENABLED_RATES_GROUP, data: ratesGroup.data})
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}

function* ratesGroupsSaga() {
   yield takeLatest(REQUEST_RATES_GROUPS, safe(onError, fetchRatesGroups));
   yield takeLatest(REQUEST_RATES_GROUP_CONFIGS, safe(onError, fetchRatesGroupsConfigs));
   yield takeLatest(REQUEST_EDIT_RATES_GROUP, safe(onError, editRatesGroups));
   yield takeLatest(REQUEST_DELETE_RATES_GROUP, safe(onError, deleteRatesGroups));
   yield takeLatest(REQUEST_ADD_RATES_GROUP, safe(onError, addRatesGroups));
   yield takeLatest(REQUEST_SORT_RATES_GROUPS, safe(onError, sortRatesGroups));
    yield takeLatest(REQUEST_IS_ENABLED_RATES_GROUP, safe(onError, putIsEnableRatesGroup));
}

export default ratesGroupsSaga;