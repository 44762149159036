import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_ADD_MIN_MAX_DURATIONS,
    REQUEST_MIN_MAX_DURATIONS,
    SET_MIN_MAX_DURATIONS,
    SET_ERROR_MSG,
    SET_ADD_MIN_MAX_DURATIONS
} from "@actions/actionTypes";
import {PutResource, GetResource} from "../../utils/HttpUtil";
import {onError, safe} from "./handlerError";

function* putMinMaxDurations({body}) {
    const minMaxDurations = yield call(PutResource, `/rate/appconfigs`, body);
    yield put({
        type: SET_MIN_MAX_DURATIONS,
        minMaxDurations: minMaxDurations.data
    })
    yield put({type: SET_ADD_MIN_MAX_DURATIONS})
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}

function* getMinMaxDurations() {
    const minMaxDurations = yield call(GetResource, `/rate/appconfigs`);
    yield put({
        type: SET_MIN_MAX_DURATIONS,
        minMaxDurations
    })
}

function* settingsSaga() {
    yield takeLatest(REQUEST_ADD_MIN_MAX_DURATIONS, safe(onError, putMinMaxDurations));
    yield takeLatest(REQUEST_MIN_MAX_DURATIONS, safe(onError, getMinMaxDurations));
}

export default settingsSaga