import {
    REQUEST_TRADING_RATES,
    REQUEST_SINGLE_TRADING_RATES,
    REQUEST_EDIT_SINGLE_TRADING_RATES,
    REQUEST_IS_ENABLED_TRADING_RATE,
    REQUEST_ASSET_STATUS, SET_ASSET_STATUS,
    SET_SINGLE_TRADING_RATES,
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export function getTradingRate(page, limit, keyLike = "", sortDirDesc = "") {
    return dispatch => {
        dispatch({
            type: REQUEST_TRADING_RATES,
            page,
            limit,
            keyLike,
            sortDirDesc
        });
    }
}

export function getSingleTradingRate(id) {
    return dispatch => {
        dispatch({
            type: REQUEST_SINGLE_TRADING_RATES,
            data : {
                id
            }
        });
    }
}

export function editSingleTradingRate(id,body) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_SINGLE_TRADING_RATES,
            data : {
                id,
                body
            }
        });
    }
}

export function resetSingleTradingRate() {
    return dispatch => {
        dispatch({
            type: SET_SINGLE_TRADING_RATES,
            singleTradingRate: {}
        });
    }
}

export function putIsEnableTradingRate(id,isEnabled) {
    return dispatch => {
        dispatch({
            type: REQUEST_IS_ENABLED_TRADING_RATE,
            data: {
                id,
                isEnabled
            }
        });
    }
}

export function getAssetStatus(key,id) {
    return dispatch => {
        dispatch({
            type: REQUEST_ASSET_STATUS,
            key,
            id
        })
    }
}

export function resetAssetStatus() {
    return dispatch => {
        dispatch({
            type: SET_ASSET_STATUS,
            assetStatus: initialStore.assetsStatus
        })
    }
}
