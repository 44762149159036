import {SET_PROVIDERS_ASSETS, SET_RATE_PROVIDER_SUCCESS} from "@actions/actionTypes";
import initialStore from "../../initialStore";

function providersAssetsReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_PROVIDERS_ASSETS:
            return  action.providersAssets
        case SET_RATE_PROVIDER_SUCCESS:
            return {
                total: state.total,
                items: state.items.map(rate => {
                    if (rate.key == action.key) {
                        let providers = rate.providers.map(provider => {
                            if (provider.id == action.provider.id) {
                                return action.provider;
                            }
                            return {
                                ...provider,
                                isActive: false
                            };
                        });
                        return {
                            key: rate.key,
                            providers
                        }
                    } else {
                        return rate;
                    }
                    
                })
            }
        default:
            return state;
    }
}

export default providersAssetsReducer