import {call, put, fork, take, cancel} from "redux-saga/effects";
import {onError, safe} from "./handlerError";
import {GetResource} from "../../utils/HttpUtil";
import {
    SET_ACTIVE_BET_LIST,
    REQUEST_ACTIVE_BET_LIST,
    CLEAR_ACTIVE_BET_LIST
} from "@actions/actionTypes";
import {getQuery} from "../../utils/CommonUtils";
import {BetType} from '@constants/Enums';

let lastTask

const takeLatest = (patternOrChannel, saga, ...args) => fork(function*() {
    lastTask
    while (true) {
      const action = yield take(patternOrChannel)
      if (lastTask) {
        yield cancel(lastTask) // cancel is no-op if the task has already terminated
      }
      lastTask = yield fork(saga, ...args.concat(action))
    }
})

function* fetchActiveBetList({params}) {
    let query = getQuery(params);
    const bets = yield call(GetResource, `/betting/bets/stats?statuses=${BetType["progress"]}${query ? `&${query}` : ""}`);
    yield put({type: SET_ACTIVE_BET_LIST, bets})
}

function* cancelFetching() {
    yield cancel(lastTask);
}

function* activeBetsSaga() {
    yield takeLatest(REQUEST_ACTIVE_BET_LIST, safe(onError, fetchActiveBetList));
    yield takeLatest(CLEAR_ACTIVE_BET_LIST, safe(onError, cancelFetching));
}

export default activeBetsSaga