import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Modal, List} from "antd";
import {
    resetPlayerList
} from "@actions/playersActions";
import LoadingList from "./LoadingList";


function PlayersListPopup({visible, isLoading, players, resetPlayerList, selectPlayer}) {
    const handleClick = (item) => {
        selectPlayer(item);
        resetPlayerList();
    }

    return (
        <Modal
            className="players-popup"
            title={<FormattedMessage id="players" />}
            visible={visible}
            onCancel={resetPlayerList}
            footer={null}
        >
            {isLoading? <LoadingList count={10}/> :
            <List
                size="small"
                header={null}
                footer={null}
                bordered
                dataSource={players}
                renderItem={item => <List.Item onClick={() => handleClick(item)}>{item}</List.Item>}
                />
            }
        </Modal>
    )
}
function mapStateToProps(state) {
    return {
        visible: state.players.listModalVisible || state.players.isLoading,
        players: state.players.list,
        isLoading: state.players.isLoading
    }
}

const mapDispatchToProps = {
    resetPlayerList
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayersListPopup);