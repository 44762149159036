import {
    SET_ERROR_MSG,
    RESET_ERROR_MSG
} from "@actions/actionTypes";

export function resetErrorMsg() {
    return dispatch => {
        dispatch({type: RESET_ERROR_MSG });
    }
}

export function setErrorMsg(value) {
    return dispatch => {
        dispatch({type: SET_ERROR_MSG, data: {type: 'error', messages: value}});
    }
}

export function setInfoMsg(value) {
    return dispatch => {
        dispatch({type: SET_ERROR_MSG, data: {type: 'info', messages: value}});
    }
}

export function setWarningMsg(value) {
    return dispatch => {
        dispatch({type: SET_ERROR_MSG, data: {type: 'warning', messages: value}});
    }
}

export function setDoneMsg(value) {
    return dispatch => {
        dispatch({type: SET_ERROR_MSG, data: {type: 'done', messages: value}});
    }
}

export function setEmptyMsg() {
    return dispatch => {
        dispatch({type: SET_ERROR_MSG, data: {type: '', messages: ""}});
    }
}