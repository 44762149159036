import { call,  put, } from 'redux-saga/effects';
import {SET_ERROR_MSG, CHANGE_LOADING} from '@actions/actionTypes';
import { isRequest, isSetData } from "../../utils/CommonUtils";


export const safe = (handler = null, saga, ...args) => function* (action) {
    try {
        yield call(saga, ...args, action)
    } catch (err) {
        let  errMsg =  window.navigator.onLine ? err : "offline"
        console.error('error handling', err)
        yield call(handler, args, errMsg, action);
    }
}

export function* onError (args, err, action) {
    err = err.toString();
    if (err.indexOf('401') || err.indexOf('403')) {
        // yield put({type: SET_USER, user: null});
    }

    let requestType = isRequest(action.type);

    yield put({type: SET_ERROR_MSG, data: {type: 'error', messages: `error_${err}`, actionType: action.type + '_ERROR', isOpenedByPopup: action.isOpenedByPopup}})

    if (requestType) {
        if (action.data && action.data.id) {
            yield put({type: CHANGE_LOADING, loading:{[requestType + action.data.id]: false}});
        } else {
            yield put({type: CHANGE_LOADING, loading:{[requestType]: false}});
        }
    }

    let setType = isSetData(action.type);

    if (setType) {
        if (action.data && action.data.id) {
            yield put({type: CHANGE_LOADING, loading:{[setType + action.data.id]: false}});
        } else {
            yield put({type: CHANGE_LOADING, loading:{[setType]: false}});
        }
    }
}