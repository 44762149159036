import {all, call} from 'redux-saga/effects';
import ratesGroupsSaga from "./ratesGroupsSaga";
import tradingRatesSaga from "./tradingRatesSaga";
import translationsSaga from "./translationsSaga";
import settingsSaga from "./settingsSaga";
import clientsSaga from "./clientsSaga";
import errorSaga from "./errorSaga";
import betsSaga from "./betsSaga";
import playersSaga from "./playersSaga";
import transactionsSaga from "./transactionsSaga";
import activeBetsSaga from "./activeBetsSaga";
import providersAssetsSaga from "./providersAssetsSaga";

export default function* rootSaga() {
    yield all([
        call(ratesGroupsSaga),
        call(tradingRatesSaga),
        call(translationsSaga),
        call(settingsSaga),
        call(clientsSaga),
        call(errorSaga),
        call(betsSaga),
        call(playersSaga),
        call(transactionsSaga),
        call(activeBetsSaga),
        call(providersAssetsSaga)
    ])
}