import initialStore from "../../initialStore";
import {
    SET_RATES_GROUPS,
    SET_EDIT_RATES_GROUP,
    REQUEST_SORT_RATES_GROUPS,
    SET_IS_ENABLED_RATES_GROUP
} from "@actions/actionTypes";

export default function ratesGroupsReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_RATES_GROUPS:
            return action.ratesGroups;
        case SET_IS_ENABLED_RATES_GROUP:
        case SET_EDIT_RATES_GROUP:
            let newItems = state.items.map(item => {
                if (item.id === action.data.id) {
                    return {
                        ...item,
                        ...action.data
                    };
                }
                return item;
            });
            return {
                total: state.total,
                items: newItems
            };
        case REQUEST_SORT_RATES_GROUPS:
            return {
                total: state.total,
                items: action.body
            }
        default:
            return state;
    }
}
