import React, {useState, useEffect} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {Table, Input, Form, Tooltip, Select, Alert, Button} from 'antd';
import {connect} from "react-redux";
import InfoPopUp from "@components/InfoPopUp/InfoPopUp";
import {getUrlValue, getUrlString} from "../../../utils/CommonUtils";
import {DefaultLanguageCode} from "@constants/Enums";
import {Prompt, useHistory, useLocation} from "react-router-dom";

import {
    fetchLangList,
    fetchTranslations,
    editTranslations,
    deleteTranslateIcon,
    setIconDeletedCondition,
    resetTranslationsUpdatedStatus,
    putTranslationIcon
} from "@actions/translationsActions";
import TableLoader from "@components/Loaders/tableLoader";
import ImgUpload from "@components/ImgUpload/ImgUpload";
import imgUploadIcon from "@assets/img/icon/upload.svg";
import imgUploadLoader from "@assets/img/icon/uploading.svg";
import classNames from "classnames";

const EditableCell = ({editing, dataIndex, children, putTranslationIcon}) => {
    return (
        <td className="noPadding">
            {
                editing ? (<Form.Item name={dataIndex} noStyle>
                    <Input />
                </Form.Item>) : (children)
            }
        </td>
    );
};

let changedFields = {}


const Translations =
    ({
         intl, fetchLangList, langList, translations, fetchTranslations, editTranslations, deleteTranslateIcon,
         translationDeletedIcon, setIconDeletedCondition, loading, isUpdated, resetTranslationsUpdatedStatus, putTranslationIcon
     }) => {
        const [form] = Form.useForm();
        let history = useHistory();
        const search = history && history.location && history.location.search;
        const [data, setData] = useState([]);
        const {Option} = Select;
        const [totalPages, setTotalPages] = useState(0);
        const [limitPage, setLimitPage] = useState(+getUrlValue(search).limitPage || 10);
        const [currentPage, setCurrentPage] = useState(+getUrlValue(search).currentPage || 1);
        const [selectedLang, setSelectedLang] = useState( getUrlValue(search).selectedLang || "");
        const [confirmDelete, setConfirmDelete] = useState(false);
        const [deletedIconId, setDeletedIconId] = useState("");
        const [confirmLeave, setConfirmLeave] = useState("");
        const [formIsHalfFilledOut, setFormIsHalfFilledOut] = useState(false);
        const [imgUploadLoading, setImgUploadLoading] = useState("");
        const currentLocation = useLocation();

        const columns = [
            {
                title: intl.formatMessage({id: "key"}),
                dataIndex: 'key',
                editable: false,
                width: "fit-content"

            },
            {
                title: intl.formatMessage({id: "value"}),
                dataIndex: 'value',
                className: 'noPadding',
                width: 178,
                editable: true,
            },
            {
                title: intl.formatMessage({id: "upload_image"}),
                editable: false,
                render: (text, record) => {
                    const icon = changedFields[record.key + "Icon"] || record.icon;

                    return (<div className={`iconBox ${icon ? "existing" : ""}`}>
                        <div className={`upload `}>
                            {
                                <>
                                    <div
                                        className={`uploadDescription ${imgUploadLoading === record.key ? "" : "visibleElement"}`}>
                                        {
                                            icon ?
                                                <Tooltip overlayClassName="imgTooltip"
                                                         title={<img src={icon} alt="icon" className="toolTipBg"/>}
                                                         color={icon}>{icon}</Tooltip>
                                                :
                                                <ImgUpload
                                                    handleLoading={(loading) => handleLoading(loading, record.key)}
                                                    onChange={(url) => uploadIcon(url, record.key)}>
                                                    <FormattedMessage id='upload'/>
                                                    <img src={imgUploadIcon} alt="icon" className="icon"/>
                                                </ImgUpload>
                                        }
                                    </div>
                                    <div
                                        className={`uploadDescription loader ${imgUploadLoading === record.key ? "visibleElement" : ""}`}>
                                        <FormattedMessage id='uploading'/>
                                        <img src={imgUploadLoader} alt="icon" className="icon loading"/>
                                    </div>
                                </>
                            }
                        </div>
                        {
                            icon ?
                                    <div className={`uploadDescription ${imgUploadLoading === record.key ? "" : "visibleElement"}`}>
                                        <ImgUpload handleLoading={(loading) => handleLoading(loading, record.key)}
                                                   onChange={(url) => uploadIcon(url, record.key)}>
                                            <img src={imgUploadIcon} alt="icon" className="icon"/>
                                        </ImgUpload>
                                        <Tooltip title={<FormattedMessage id='delete'/>} color={"#fff"}
                                                 placement="bottom">
                                            <div className="delete-icon icon" onClick={() => {
                                                setConfirmDelete(icon);
                                                setDeletedIconId(record.key)
                                            }}/>
                                        </Tooltip>
                                    </div>
                                : ""
                        }

                    </div>)
                }
            }
        ]

        const mergedColumns = columns.map((col) => ({
            ...col,
            onCell: (record) => ({
                record,
                dataIndex: record.key,
                title: col.title,
                editing: col.editable,
            }),
        }));

        const onfinish = (data) => {
            if (Object.keys(changedFields).length) {
                let body = [];
                Object.keys(changedFields).forEach(key => body = [...body, changedFields[key]]);
                editTranslations(body)
            }


        }

        const handleLoading = (loading, key) => {
            setImgUploadLoading(loading ? key : "")
        }

        useEffect(() => {
            if (data && data.length) {
                form.setFieldsValue(Object.assign(...data.map(d => ({
                    [d.key]: d.value
                }))))
            }
        }, [data])

        const detectFieldsChange = (key, value) => {
            data.forEach(item => {
                if (item.key == key) {
                    if (item.value === value.value) {
                        delete changedFields[key]
                    } else {
                        changedFields[key] = value
                    }
                }
            })
            if (Object.keys(changedFields).length) {
                setFormIsHalfFilledOut(true)
            } else {
                setFormIsHalfFilledOut(false)
            }
        }

        const onFieldsChange = (field) => {
            const key = Object.keys(field)[0];
            const element = data.filter(elem => elem.key === key)[0]
            if (element) {
                const value = Object.assign({}, element);
                detectFieldsChange(key, {...value, value: Object.values(field)[0]})
            }
        }

        const deleteIcon = () => {
            deleteTranslateIcon(confirmDelete, deletedIconId);
        }

        const uploadIcon = (icon, key) => {
            putTranslationIcon( {"name": key, icon})
        }


        useEffect(() => {
            if (translations && translations.items) {
                setData(translations.items)
                setTotalPages(translations.total)
            }
        }, [translations]);

        useEffect(() => {
            if (langList && !selectedLang) {
                setSelectedLang(DefaultLanguageCode)
                const {params} =  getUrlString({currentPage, limitPage, selectedLang: DefaultLanguageCode})
                history.replace({search: params.toString()})
            }
        }, [langList])

        useEffect(() => {
            if (isUpdated) {
                changedFields = {}
                setFormIsHalfFilledOut(false)
                resetTranslationsUpdatedStatus()
                }
        }, [isUpdated])

        useEffect(() => {
            if (translationDeletedIcon) {
                setConfirmDelete(false)
                setIconDeletedCondition("")
                setDeletedIconId("")
            }

        }, [translationDeletedIcon])

        useEffect(() => {
            fetchLangList()
        }, []);

        const cancelChanges = () => {
            form.setFieldsValue(Object.assign(...translations.items.map(d => ({
                [d.key]: d.value
            }))))
            changedFields = {}
            setFormIsHalfFilledOut(false)
        }

        useEffect(() => {
            fetchTranslations(selectedLang || DefaultLanguageCode, limitPage, currentPage);
        }, [])

        const changeLanguage = (lang) => {
            if (selectedLang != lang.value) {
                setSelectedLang(lang.value)
                setCurrentPage(1)
                const {params} =  getUrlString({currentPage: 1, limitPage, selectedLang: lang.value})
                history.push({search: params.toString()})
            }

        }

        const handlePaging = (page, limit) => {
            let newPage = 1;

            setLimitPage(l => {
                if (l === limit) newPage = page;
                setCurrentPage(newPage);
                return limit;
            });
            const {params} =  getUrlString({currentPage: newPage, limitPage: limit, selectedLang})
            history.push({search: params.toString()})
        }

        useEffect(() => {
            return history.listen((location) => {
                const loc = location.search
                if (loc) {
                    const {currentPage, limitPage, selectedLang} = getUrlValue(loc)
                    fetchTranslations(selectedLang || DefaultLanguageCode, limitPage, currentPage);
                    setSelectedLang(selectedLang)
                    setLimitPage(+limitPage)
                    setCurrentPage(+currentPage)
                }
            })
        },[history])
        return (
            <div>
                <Prompt when={formIsHalfFilledOut}
                        message={location => {
                            if (location.pathname !== currentLocation.pathname) {
                                setConfirmLeave(location.pathname)
                                if (!confirmLeave) {
                                    return false
                                }
                            }
                        }}/>
                <div className="content translate">
                    <div className="titleArea">
                        <div className="titleArea--title"><FormattedMessage id='translations'/></div>
                    </div>
                    <div className="toolBar">
                        <label className="label">
                            <FormattedMessage id="select_language"/>
                            <Select
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                labelInValue
                                style={{width: 120}}
                                value={selectedLang && langList.length ? {value: langList.filter(i => i.id == selectedLang)[0].displayName} : []}
                                onChange={(lang) => changeLanguage(lang)}
                                placeholder={<FormattedMessage id="select_language"/>}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {
                                    langList && langList.length ? langList.map(lang => (
                                        <Option key={lang.id} value={lang.id}>{lang.displayName}</Option>
                                    )) : ""
                                }

                            </Select>
                        </label>


                        <div className="centeredBox btnBox">
                            <button className="miniBtn default"  onClick={cancelChanges}>
                                <FormattedMessage id={"cancel"}/></button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className="miniBtn"
                                    onClick={() => form.submit()}
                                    loading={loading._edit_translations}>
                                <FormattedMessage id={"save"}/>
                            </Button>
                        </div>
                    </div>
                    <div className="mainTable translation">
                        <Form form={form} component={false} onFinish={onfinish} onValuesChange={onFieldsChange}>
                            {
                                loading._translations ? <TableLoader count={limitPage} column={3} icon={false} drag={false} actions={0}/> :
                                    <Table
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        dataSource={data}
                                        columns={mergedColumns}
                                        scroll={{x:'100%'}}
                                        pagination={
                                            {
                                                showSizeChanger: true,
                                                defaultCurrent: currentPage,
                                                defaultPageSize: limitPage,
                                                showLessItems: true,
                                                onChange: (page, limit) => handlePaging(page, limit),
                                                total: totalPages,
                                                showTotal: (total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`
                                            }
                                        }
                                    />
                            }
                        </Form>
                    </div>
                    {confirmDelete ? <InfoPopUp show={confirmDelete}
                                                onCancel={() => setConfirmDelete(false)}
                                                onSubmit={() => deleteIcon()}
                                                title="delete_image"
                                                description="delete_image_description"/> : ""}
                    {confirmLeave ? <InfoPopUp show={confirmLeave}
                                               onCancel={() => setConfirmLeave("")}
                                               onSubmit={() => history.push(confirmLeave)}
                                               title="confirm_navigation"
                                               description="confirm_navigation_description"
                                               cancelText="stay_on_this_page"
                                               confirmText="leave_this_page"/> : ""}
                </div>
            </div>
        )
    };

function mapStateToProps(state) {
    return {
        langList: state.langList,
        translations: state.translations,
        translationDeletedIcon: state.translationDeletedIcon,
        loading: state.loading,
        isUpdated: state.translationUpdated
    }
}

const mapDispatchToProps = {
    fetchLangList,
    fetchTranslations,
    editTranslations,
    deleteTranslateIcon,
    setIconDeletedCondition,
    resetTranslationsUpdatedStatus,
    putTranslationIcon
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Translations));
