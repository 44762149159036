import {
    REQUEST_PROVIDERS_ASSETS,
    SET_RATE_PROVIDER
}  from "@actions/actionTypes";


export function getProvidersAssets(page, limit, keyLike = "", sortDirDesc = "") {
    return dispatch => {
        dispatch({
            type: REQUEST_PROVIDERS_ASSETS,
            page,
            limit,
            keyLike,
            sortDirDesc
        });
    }
}

export function setRateProvider(id, key) {
    return dispatch => {
        dispatch({
            type: SET_RATE_PROVIDER,
            id,
            key
        });
    }
}