import React, { useState, useEffect} from 'react'
import { Layout } from 'antd';
import LeftBare from '../../components/LeftBare'
import MainHeader from '../../components/Header'
import routes from "../../routes";
import {
    Switch,
    Redirect,
    Route,
    useHistory
  } from "react-router-dom";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined
} from '@ant-design/icons';
import "./style.sass";
import AlertMessage from "@components/AlertMessage/AlertMessage";
import {connect} from "react-redux";
import {startCsvExport} from "@actions/glogalActions";
import "../../redux/sagas/csvProcess";

const { Sider, Content } = Layout;

function MainPage({startCsvExport, path, user}) {
    const [collapsed, setCollapsed] = useState(false);
    const [online, setOnline] = useState(window.navigator.onLine)
    const history = useHistory();

    useEffect(() => {
        function enable(e) { setOnline(true) }
        function disable(e) { setOnline(false) }
        window.addEventListener('online', enable);
        window.addEventListener('offline', disable);

        return () => {
            window.removeEventListener('online', enable);
            window.removeEventListener('offline', disable);
        }
    },[])

    useEffect(() => {
        const csvFileProcess = window.CsvProcess.getStore();
        if (csvFileProcess.length && online) {
            startCsvExport(csvFileProcess);
        }
    }, [online])

    return (
        <>
        <MainHeader apps={user?.profile?.Apps} isSuperAdmin={user?.profile?.IsSuperAdmin}/>
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}  collapsedWidth={56} width={256}>
                <LeftBare/>
                <div className="bar-trigger">
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: () => setCollapsed(!collapsed),
                    })}
                </div>
                
            </Sider>
            <Layout className="site-layout">
                <AlertMessage />
                <Content
                    className="site-layout-background"
                    style={{
                    margin: 24,
                    padding: '0 24px',
                    minHeight: 'unset',
                    }}
                >
                    <Switch>
                        {routes.map((route, i) => (
                            <Route
                                key={i}
                                path={route.path}
                                render={props => (
                                    <route.component {...props}/>
                                )}
                            />
                        ))}
                        
                        <Redirect from="/callback/login" to={path} />
                        <Redirect from="/login" to={path} />
                        <Route exact path="/:all" render={() => <Redirect to="not-found" />}/>
                    </Switch>
                </Content>
            </Layout>
        </Layout>
        </>
    )
}

function mapStateToProps(state) {
    return {
        path: state.path,
        user: state.user
    }
}

const mapDispatchToProps = {
    startCsvExport
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);