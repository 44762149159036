import React, {useContext, useEffect} from "react";
import {Button, Col, Modal, Row} from 'antd';
import { FormattedMessage, injectIntl } from "react-intl";
import { REQUEST_DELETE_RATES_GROUP, REQUEST_IS_ENABLED_TRADING_RATE } from "@actions/actionTypes";
import {ResponseContext} from "../../hooks/ResponseObserver";


function InfoPopUp({ show, title, description, onCancel, onSubmit, cancelText, confirmText, descriptionVariable, intl, descriptionSecond, loading = false}) {
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    useEffect(() => {
        subscribeForError(REQUEST_DELETE_RATES_GROUP, () => {
            onCancel();
        });

        return () => {
            unSubscribeForError(REQUEST_DELETE_RATES_GROUP);
        }
    }, [])

    return (<Modal
        title={<FormattedMessage id={title} />}
        centered
        visible={show}
        width={448}
        onCancel={() => onCancel()}
        className="formModal"
        footer={false}>
        <Row className="MainModal infoPopUp">
            <Col span={24} className="MainModal--upload MainModal--parts">
                <div className="MainModal--parts--content">
                  <p>  { description ? intl.formatMessage( {id: description }, {...descriptionVariable}) : "" }</p>
                  <p>  { descriptionSecond ? intl.formatMessage( {id: descriptionSecond }) : "" }</p>
                </div>
            </Col>
        </Row>
        <div className="MainModal--footer d-flex justify-end">
            <button type="reset" className="miniBtn default" onClick={onCancel}>
                <FormattedMessage id={cancelText ? cancelText : 'cancel'} />
            </button>
            <Button type="primary"
                    htmlType="miniBtn"
                    style={{height: 36}}
                    className="miniBtn"
                    loading={loading}
                    onClick={onSubmit}
            >
                <FormattedMessage id={confirmText ? confirmText : 'save'} />
            </Button>
        </div>
    </Modal>)
}

export default injectIntl(InfoPopUp);