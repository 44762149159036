import {
    REQUEST_PLAYERS_BY_IP,
    CLEAR_PLAYERS_BY_IP
} from "@actions/actionTypes";

export function getPlayersByIp(ip) {
    return dispatch => {
        dispatch({
            type: REQUEST_PLAYERS_BY_IP,
           ip
        });
    }
}

export function resetPlayerList() {
    return dispatch => {
        dispatch({
            type: CLEAR_PLAYERS_BY_IP
        });
    }
}