import {
    REQUEST_RATES_GROUPS,
    REQUEST_DELETE_RATES_GROUP,
    REQUEST_RATES_GROUP_CONFIGS,
    REQUEST_EDIT_RATES_GROUP,
    REQUEST_ADD_RATES_GROUP,
    REQUEST_SORT_RATES_GROUPS,
    REQUEST_IS_ENABLED_RATES_GROUP,
    SET_RATES_GROUPS
} from "@actions/actionTypes";
import {DefaultRatesGroupId} from "@constants/Enums";


export function getRatesGroups(currentPage, limitPage) {
    return dispatch => {
        dispatch({
            type: REQUEST_RATES_GROUPS,
            currentPage,
            limitPage
        });
    }
}

export function getRatesGroupsConfigs(id) {
    return dispatch => {
        dispatch({
            type: REQUEST_RATES_GROUP_CONFIGS,
            data: {id}
        });
    }
}

export function editRatesGroups(body,id) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_RATES_GROUP,
            data: {
                body,
                id
            }

        });
    }
}

export function addRatesGroupsConfigs(body) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_RATES_GROUP,
            data : {
                body,
                id: DefaultRatesGroupId
            }
        });
    }
}

export function deleteRatesGroups(id) {
    return dispatch => {
        dispatch({type: REQUEST_DELETE_RATES_GROUP,id});
    }
}

export function sortRatesGroupsConfigs(body) {
    return dispatch => {
        dispatch({
            type: REQUEST_SORT_RATES_GROUPS,
            body
        });
    }
}



export function putIsEnableRatesGroup(id, isEnabled) {
    return dispatch => {
        dispatch({
            type: REQUEST_IS_ENABLED_RATES_GROUP,
            data: {
                id,
                isEnabled
            }

        });
    }
}

export function setRatesGroups(ratesGroups) {
    return dispatch => {
        dispatch({
            type: SET_RATES_GROUPS,
            ratesGroups
        })
    }
}