import {
    REQUEST_BET_CSV_DOWNLOADER_URL,
    REQUEST_BET_LIST,
    REQUEST_BET_LIST_FILTER,
    REQUEST_CANCEL_BET_CSV_DOWNLOAD
} from "@actions/actionTypes";

export function getBetList(params) {
    return dispatch => {
        dispatch({
            type: REQUEST_BET_LIST,
            params
        })
    }
}

export function getBetListFilter() {
    return dispatch => {
        dispatch({
            type: REQUEST_BET_LIST_FILTER
        })
    }
}

export function getBetsCsvLink(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_BET_CSV_DOWNLOADER_URL,
            data
        })
    }
}

export function cancelBetCsvDownload() {
    return dispatch => {
        dispatch({
            type: REQUEST_CANCEL_BET_CSV_DOWNLOAD
        })
    }
}