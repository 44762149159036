import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getFormattedUrlObject, getUrlString, getUrlValue} from "../../../utils/CommonUtils";
import TableLoader from "@components/Loaders/tableLoader";
import {Table} from "antd";
import PlayersByIp from "@components/PlayersByIp";
import {getBetList} from "@actions/betsActions";
import {BetType,  GamePrediction} from "@constants/Enums";
import moment from "moment-timezone";
import {timestampToParts} from "@services/TradingRatesService";
import PlayersListPopup from "@components/PlayersListPopup";
import classNames from "classnames";
import predictionAll from "@assets/img/icon/all.svg";
import predictionUp from "@assets/img/icon/up.svg";
import predictionDown from "@assets/img/icon/down.svg";
import {TableProfit} from "@components/TableProfit/TableProfit";
import BetsFilter from "@components/BetsFilter/BetsFilter";
import {setDoneMsg,setErrorMsg} from "@actions/alertAction";
import {copyLabel} from "@services/TableServices";

const predictionIcons = [predictionAll, predictionUp, predictionDown]


function Bets({intl, loading, getBetList, bets, setErrorMsg, setDoneMsg}) {
    let history = useHistory()
    let search = history && history.location && history.location.search

    const [totalPages, setTotalPages] = useState(0)
    const [limit, setLimit] = useState(+getUrlValue(search).limit || 10)
    const [page, setPage] = useState(+getUrlValue(search).page || 1)
    const [profit, setProfit] = useState({})
    const [showFilter, setShowFilter] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState("")
    const columns = [
        {
            title: intl.formatMessage({id: "client"}),
            dataIndex: 'clientName',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetType[record.status] + "_bg"}`}>{record.clientName}</div>
            }
        },
        {
            title: intl.formatMessage({id: "player"}),
            dataIndex: 'playerName',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetType[record.status] + "_bg"}`}>{record.playerName}</div>
            }
        },
        {
            title: intl.formatMessage({id: "date"}),
            dataIndex: 'dateCreated',
            width: "fit-content",
            render: (text, record) => {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                return <div className={`${BetType[record.status] + "_bg"}`}>{moment(record.dateCreated).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")}</div>
            }

        },
        {
            title: intl.formatMessage({id: "amount"}),
            dataIndex: 'amount',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetType[record.status] + "_bg"}`}>{record.amount}</div>
            }
        },
        {
            title: intl.formatMessage({id: "asset"}),
            dataIndex: 'asset',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetType[record.status] + "_bg"}`}>{record.asset}</div>
            }
        },
        {
            title: intl.formatMessage({id: "prediction"}),
            dataIndex: 'prediction',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`prediction ${BetType[record.status] + "_bg"}`}><img src={predictionIcons[record.prediction]}
                                                         alt=""/><FormattedMessage
                    id={GamePrediction[record.prediction]}/></div>
            }

        },
        {
            title: intl.formatMessage({id: "status"}),
            dataIndex: 'status',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetType[record.status] + "_bg"} ${BetType[record.status]}`}><FormattedMessage
                    id={BetType[record.status]}/></div>
            }

        },
        {
            title: intl.formatMessage({id: "time"}),
            dataIndex: 'time',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetType[record.status] + "_bg"}`}>{timestampToParts(record.duration || record.time, intl)} </div>
            }

        },
        {
            title: intl.formatMessage({id: "profit"}),
            dataIndex: 'win',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetType[record.status] + "_bg"}`}>{record.win} </div>
            }
        },
        {
            title: intl.formatMessage({id: "rate"}),
            dataIndex: 'rate',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetType[record.status] + "_bg"}`}>{record.rate}</div>
            }
        },
        {
            title: intl.formatMessage({id: "payout"}),
            dataIndex: 'payout',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetType[record.status] + "_bg"}`}>{record.payout}</div>
            }
        },
        {
            title: intl.formatMessage({id: "ip"}),
            dataIndex: 'ip',
            width: "fit-content",
            render: (text, record) => {
                return  <PlayersByIp label={record.ip} classN={`${BetType[record.status] + "_bg"}`}/>
            }
        },
        {
            title: intl.formatMessage({id: "closing_rate"}),
            dataIndex: 'rateExpire',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetType[record.status] + "_bg"}`}>{record.rateExpire}</div>
            }
        },
        {
            title: intl.formatMessage({id: "session"}),
            dataIndex: 'session',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`copyable ${BetType[record.status] + "_bg"}`} onDoubleClick={(e) => {
                    copyLabel(e, record.session)
                        .then(() => {
                            setDoneMsg("text_is_copied")
                        }).catch(() => {
                        setErrorMsg("something_wrong_the_text_is_not_copied")
                    })
                }}>{record.session}</div>
            }
        },
        {
            title: intl.formatMessage({id: "close_date"}),
            dataIndex: 'dateExpire',
            width: "fit-content",
            render: (text, record) => {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                return <div className={`${BetType[record.status] + "_bg"}`}>{moment(record.dateExpire).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")}</div>
            }

        }
    ];

    const getBetsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj)
        return {
            ...initialForm,
            statuses: initialForm.statuses ? initialForm.statuses.toString() : "",
            types: initialForm.types ? initialForm.types.toString() : "",
            predictions: initialForm.predictions ? initialForm.predictions.toString() : "",
            assets: initialForm.assets ? initialForm.assets.toString() : "",
            playerNameLike: selectedPlayer ? selectedPlayer : (initialForm.playerNameLike || ""),
            dateCreatedRange:{
                start: initialForm["dateCreatedRange"] ? moment(initialForm["dateCreatedRange"]["start"]).utc().format() : "",
                end: initialForm["dateCreatedRange"] ? moment(initialForm["dateCreatedRange"]["end"]).utc().format() : ""
            },
            dateExpireRange:{
                start: initialForm["dateExpireRange"] ? moment(initialForm["dateExpireRange"]["start"]).utc().format() : "",
                end: initialForm["dateExpireRange"] ? moment(initialForm["dateExpireRange"]["end"]).utc().format() : ""
            },
        }
    }

    const fetchBets = (isFirst) => {
        let initialForm = getBetsRequestBody()
        initialForm = {
            ...initialForm,
            page : initialForm.page || page,
            limit: initialForm.limit || limit,
            playerNameLike: selectedPlayer ? selectedPlayer : (initialForm.playerNameLike || "")
        }
        if(Object.keys(initialForm).length) {
            getBetList(initialForm)
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        fetchBets(true)
    }, [])

    useEffect(() => {
        return history.listen((location, action) => {
            const loc = location.search
            if (loc) {
                const initialForm = getBetsRequestBody(loc)
                getBetList(initialForm)
                setPage(+initialForm.page || page)
                setLimit(+initialForm.limit || limit)
            }
        })
    },[history])

    useEffect(() => {
        if (selectedPlayer) {
            const initialForm = getBetsRequestBody()
            const {params} = getUrlString({
                clientId: initialForm.clientId,
                page: 1,
                limit,
                playerNameLike: selectedPlayer
            })

            setPage(1)
            history.push({search: params.toString()})
            setSelectedPlayer("")
        }

    }, [selectedPlayer])


    useEffect(() => {
        if (bets && bets.items) {
            setTotalPages(bets.total);
            setProfit(bets.profit || {});
        }
    }, [bets]);

    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;
        if (limit === changedLimit) newPage = changedPage;
        setPage(newPage);
        setLimit(changedLimit);
        const initialForm = getBetsRequestBody()
        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()})
    }


    return (
        <div className="content bets">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='bets'/></div>
                <button className={classNames("filterBtn", {show: showFilter})} onClick={() => setShowFilter(prev => !prev)}>
                    <div className="icon"/>
                    <FormattedMessage id='filter'/>
                </button>
            </div>
            <BetsFilter limit={limit}
                        setPage={setPage}
                        showFilter={showFilter}
                        selectedPlayer={selectedPlayer}
            />
            <div className="bets">
                <div className="mainTable">
                    {loading._bet_list ? <TableLoader count={limit} column={8} icon={false} drag={false} actions={0}/> :
                        <div>
                            <Table dataSource={bets.items || []}
                                   columns={columns}
                                   rowKey="id"
                                   rowClassName={(record) => `${BetType[record.status] + "_bg"}`}
                                   scroll={{x: '100%'}}
                                   pagination={false}
                            />
                            <TableProfit profit={profit}
                                         totalPages={totalPages}
                                         handleTablePaginate={handleTablePaginate}
                                         limit={limit}
                                         page={page}
                                         intl={intl}/>
                        </div>
                    }
                </div>

            </div>
            <PlayersListPopup selectPlayer={setSelectedPlayer}/>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        bets: state.betsList,
        loading: state.loading,
    }
}

const mapDispatchToProps = {
    getBetList,
    setErrorMsg,
    setDoneMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Bets));