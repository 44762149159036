export const DefaultLanguage = 'EN';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];

export const GamePrediction = {
     0: "all",
     1: "up",
     2: "down"
}

export const TransactionType = {
    0: "all",
    1: "bet",
    2: "win",
    3: "lose",
    4: "refund"
}

export const BetType = {
    0: "all",
    1: "progress",
    2: "win",
    3: "lose",
    4: "refund",
    "all": 0,
    "progress": 1,
    "win": 2,
    "lose": 3,
    "refund": 4
}

export const TransactionStatus = {
    0: "all",
    1: "success",
    2: "progress",
    3: "failed",
    4: "timeout",
    5: "trying"
}


export const Providers = {
    liverates: 0,
    xignite: 1
}

export const AssetProviderStatus = {
    inactive: 0,
    active: 1,
    suspended: 2
}

export const AssetProviderStatusType = Object.keys(AssetProviderStatus);

export const DefaultRatesGroupId = "00000000-0000-0000-0000-000000000000"

export const marks = {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
}

export const CsvProgresstype = {
    inprogress: 1,
    finished: 2,
    error: 3,
    cancelled: 4,
    emptyTable: 5
}

export const GameTemplateCurrency = [
    "USD",
    "GBP",
    "INR",
    "HKD",
    "AED",
];