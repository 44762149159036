import {transform} from 'lodash';
import {encodeRequestBody} from './HttpUtil';
import {DefaultLanguage, LanguageForApi} from "@constants/Enums";
import moment from "moment-timezone";

export function transformKeysToLowerCase(data) {
    return transform(data, function (result, val, key) {
        result[key.toLowerCase()] = val;
    })
}

export function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

export const Re8Characers = /(?!.*\s).{8,}$/;
export const ReLowerCase = /(?=(.*[a-z]){1,})/;
export const ReUpperCase = /^(?=.*?[A-Z])/;
export const ReNumber = /\d/;

export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export function correctedFilter(filter) {
    let keys = Object.keys(filter).filter(item => filter[item]);
    let obj = {};
    keys.forEach(k => {
        obj[k] = filter[k];
    });
    return obj;
}

export function grtParamsQueryByFilter(filter, pagination) {
    let filterParams = encodeRequestBody({
        ...filter,
        Lang: LanguageForApi[(localStorage.getItem('lang') || DefaultLanguage)]
    });
    let paginationParams = pagination ? '&' + encodeRequestBody({
        Page: pagination.Page,
        Limit: pagination.Limit
    }) : '';
    return `?${filterParams}${paginationParams}`;
}

export const addNameProperty = (obj) => {
    obj.name = obj[(localStorage.getItem('lang') || DefaultLanguage)];
    return obj
}

export function correctedBy(list) {
    return list.map((item, i) => {
        return {
            id: item.id,
            orderId: i + 1
        }
    });
}

export function addIndexWithDragDrop(data) {
    if (data && data.length > 0) {
        data.sort((a, b) => a.orderId - b.orderId).forEach((item, index) => {
            item.index = index + 1;
        });
        return data;
    }
    return [];
}

function filterActions(type, key) {
    let t;
    if (type.search(key) != -1) {
        t = type.replace(key, '').toLowerCase();
    }
    return t;
}

export function isRequest(type) {
    return filterActions(type, 'REQUEST');
}

export function isSetData(type) {
    return filterActions(type, 'SET');
}

export function isAddOrEditData(type) {
    return filterActions(type, 'ADD') || filterActions(type, 'EDIT');
}

export function isSetDataAndCsvUpload(type) {
    return (filterActions(type, 'SET') && filterActions(type, 'CSV'));
}

export const getUrlValue = (str) => {
    const urlParams = {}
    if (str) {
        const url = new URLSearchParams(str)
        for (const [key, value] of url) {
            if (key === "ip") {
                const ipRegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g.test(value);
                if (!ipRegExp) continue;
            }
            urlParams[`${key}`] = value || ''
        }
    }
    return urlParams;

}

export const getUrlString = (urlParams) => {
    let params = new URLSearchParams();

    Object.keys(urlParams).forEach(item => {
        if (typeof urlParams[item] !== 'object') {
            if (urlParams[item]) {
                params.set(item, urlParams[item])
            }
        } else {
            if (urlParams[item].start) {
                params.set(""+item+"Start", urlParams[item].start)
            }
            if (urlParams[item].end) {
                params.set(""+item+"End", urlParams[item].end)
            }
        }
    });
    return {params}
}

export const getFormattedUrlObject = (urlObj) => {
    let initialObj = {};

    if (Object.keys(urlObj).length) {
        Object.keys(urlObj).forEach(urlParam => {
            if(urlParam.indexOf("Range") > -1) {
                const position = urlParam.indexOf("End") > -1 ? "end" : "start"
                const changedKey = urlParam.replace("Start","")
                    .replace("End","");
                (!initialObj[changedKey]) && (initialObj[changedKey] = {});
                initialObj[changedKey][position] = urlParam.indexOf("date") > -1 ? moment(urlObj[urlParam]) : urlObj[urlParam]
            }
            else {
                initialObj[urlParam] = urlObj[urlParam]
            }

        })
    }
    return initialObj
}

export function getQuery(params) {
    let query = '';
    Object.keys(params).forEach(item => {
        if (typeof params[item] !== 'object') {
            if(params[item] !== "") {
                if (item === "statuses" || item === "types" || item === "predictions" || item === "assets") {
                    query += item + '=' + params[item] + '&';
                } else {
                    query += item + '=' +  encodeURIComponent(params[item]) + '&';
                }

            }
        } else {
            if (params[item].start) {
                query += item + '.start=' + (params[item].start || "") + '&';
            }
            if( params[item].end) {
                query += item + '.end=' + (params[item].end || "") + '&';
            }

        }
    })

    if (query) {
        query = query.slice(0, -1);
    }

    return query
}