import initialStore from "../../initialStore";
import { SET_SINGLE_TRADING_RATES } from "@actions/actionTypes";

export default function singleTradingRateReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_SINGLE_TRADING_RATES:
            return action.data
        default:
            return state;
    }
}
