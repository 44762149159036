import React, {useContext, useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    getRatesGroupsConfigs,
    editRatesGroups,
    addRatesGroupsConfigs
} from "@actions/ratesGroupsActions";

import {Modal, Form, Input, Row, Col, Button} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import ImgUpload from "@components/ImgUpload/ImgUpload";
import arrayMove from 'array-move';
import SortableContainer from "@components/ControlRatesGroups/SortableContainer";
import RatesGroupsModalLoader from "@components/Loaders/ratesGroupsModalLoader";
import {DefaultRatesGroupId} from "@constants/Enums";
import {REQUEST_EDIT_RATES_GROUP, REQUEST_ADD_RATES_GROUP} from "@actions/actionTypes";
import {ResponseContext} from "../../hooks/ResponseObserver";

function ControlRatesGroup({
                               show,
                               handleCancel,
                               group,
                               getRatesGroupsConfigs,
                               intl,
                               ratesGroupsConfigs,
                               editRatesGroups,
                               addRatesGroupsConfigs,
                               loading
                           }) {

    const [form] = Form.useForm();

    const [configs, setConfigs] = useState([])
    const [groupId] = useState(group.id || DefaultRatesGroupId);
    const [currencyFieldError, setCurrencyFieldError] = useState(false)

    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

   const onSortEnd = ({oldIndex, newIndex}) => {
       setConfigs(arrayMove(configs, oldIndex, newIndex));
    }

    const changeConfigs = (config) => {
        setCurrencyFieldError(true)
        const editedConfigs = JSON.parse(JSON.stringify(configs))
        editedConfigs.map (item => {
            if(item.id === config.id) {
                item.isSelected = !item.isSelected
            }
            if(item.isSelected){
                setCurrencyFieldError(false)
            }
            return item

        })
        setConfigs(editedConfigs)
    }

    const onFinish = (data) => {
        const pairConfigs = [];
        configs.forEach(config => {
            if (config.isSelected) {
                pairConfigs.push({
                    id: config.id,
                    index: config.index
                })
            }
        })
        pairConfigs.forEach((item, index) => {
            pairConfigs[index].index = index + 1;
        })
        if (groupId === DefaultRatesGroupId) {
            addRatesGroupsConfigs({...data, index: 0, pairConfigs})
        } else {
            editRatesGroups({...data, pairConfigs}, groupId)
        }
    }

    useEffect(() => {
        if (ratesGroupsConfigs.ratesGroup && group.id) {
            form.setFieldsValue(ratesGroupsConfigs.ratesGroup)
        } else {
            form.setFieldsValue({
                name: "",
                icon: ""
            });
            setCurrencyFieldError(true)
        }
        setConfigs(ratesGroupsConfigs.ratesGroupsConfigs);

    }, [ratesGroupsConfigs])

    useEffect(() => {
        getRatesGroupsConfigs(group.id || "")
    }, [])

    useEffect(() => {
        if (show === "edit") {
            subscribeForError(REQUEST_EDIT_RATES_GROUP, () => {
                handleCancel();
            }, groupId);
        } else {
            subscribeForError(REQUEST_ADD_RATES_GROUP, () => {
                handleCancel();
            }, DefaultRatesGroupId);
        }
        return () => {
            unSubscribeForError(REQUEST_ADD_RATES_GROUP);
            unSubscribeForError(REQUEST_EDIT_RATES_GROUP);
        }
    }, [])


    return (
        <>
            {

                <Modal
                    title={show === "add" ? <FormattedMessage id='add_rates_group'/> :
                        <FormattedMessage id='edit_rates_group'/>}
                    centered
                    className={`formModal ${loading[`_rates_group_configs${groupId}`] || loading._rates_group_configs ? "loading" : ""}`}
                    visible={show}
                    width={809}
                    onCancel={handleCancel}
                    footer={false}>
                    {loading[`_rates_group_configs${groupId}`] || loading._rates_group_configs ?
                        <RatesGroupsModalLoader/> :
                        <Form
                            layout="vertical"
                            form={form}
                            name="trading-form"
                            onFinish={onFinish}
                            scrollToFirstError
                            validateTrigger="onBlur"
                        >
                            <Row className="MainModal">
                                <Col span={12} className="MainModal--upload MainModal--parts">
                                    <div className="MainModal--parts--content">
                                        <Form.Item
                                            name="name"
                                            label={<FormattedMessage id='name'/>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <FormattedMessage id='name_validator'/>
                                                },
                                                ({getFieldValue}) => ({
                                                    validator(_, value) {

                                                        if (value && !(value.length >= 3 && value.length <= 50))
                                                        {
                                                            return Promise.reject(intl.formatMessage({id: `group_name_valid`}))
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                })
                                            ]}
                                        >
                                            <Input autoComplete="off" placeholder={intl.formatMessage({id: `type_name`})}/>
                                        </Form.Item>
                                        <Form.Item label={<FormattedMessage id='upload_logo'/>}>
                                            <Form.Item name="icon"
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: <FormattedMessage id='icon_validator'/>
                                                           },
                                                       ]} className="big-upload-img">
                                                <ImgUpload onChange={() =>  form.validateFields(["icon"])}/>
                                            </Form.Item>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12} className="MainModal--parts">
                                    <div className="MainModal--parts--content">
                                        <div className="withList">
                                            <Form.Item label={<FormattedMessage id='select_currency'/>} required={true}
                                                       className="reversedLabel">
                                                <SortableContainer onSortEnd={onSortEnd}
                                                                   useDragHandle
                                                                   lockToContainerEdges={true}
                                                                   lockAxis="y"
                                                                   items={configs}
                                                                   changeConfigs={changeConfigs}/>
                                            </Form.Item>
                                        </div>
                                        <span
                                            className={`requiredMsg ${currencyFieldError ? "error" : ""}`}><FormattedMessage
                                            id={"you_must_select_currency"}/></span>
                                    </div>
                                </Col>
                            </Row>
                            <Form.Item className="MainModal--footer">
                                <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                    <FormattedMessage id='cancel'/>
                                </button>
                                <Button type="primary"
                                        htmlType="submit"
                                        style={{height: 36}}
                                        className="miniBtn"
                                        disabled={currencyFieldError}
                                        loading={loading[`_add_rates_group${DefaultRatesGroupId}`] || loading[`_edit_rates_group${groupId}`]}
                                >
                                    <FormattedMessage id='save'/>
                                </Button>
                            </Form.Item>
                        </Form>
                    }

                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        ratesGroupsConfigs: state.ratesGroupsConfigsReducer,
        loading: state.loading
    }
}

const mapDispatchToProps =
    {
        getRatesGroupsConfigs,
        editRatesGroups,
        addRatesGroupsConfigs,
    }

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ControlRatesGroup));