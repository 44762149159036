import React from "react";

const TradingRatesModalLoader = () => {
    return (
        <div className="modalLoader">
            <div className="modalHeader">
                <div className="title"/>
                <div className="close"/>
            </div>
            <div className="modalLoaderRow">
                <div className="modalLoaderCol mainCol leftSide">
                    <div className="label"/>
                    <div className="input">
                        <div className="value"/>
                    </div>
                    <div className="labelMiddle"/>
                    <div className="image">
                        <div className="icon"/>
                        <div className="title"/>
                        <div className="description"/>
                    </div>
                </div>
                <div className="modalLoaderCol mainCol rightSide">
                    <div className="modalLoaderRow">
                        <div className="modalLoaderCol listItem">
                            <div className="label"/>
                            <div className="input">
                                <div className="value"/>
                            </div>
                        </div>
                        <div className="modalLoaderCol">
                            <div className="label"/>
                            <div className="input">
                                <div className="value"/>
                            </div>
                        </div>
                    </div>
                    <div className="modalLoaderRow">
                        <div className="modalLoaderCol listItem">
                            <div className="label"/>
                            <div className="input smallMargin">
                                <div className="value"/>
                            </div>
                        </div>
                        <div className="modalLoaderCol">
                            <div className="label"/>
                            <div className="input smallMargin">
                                <div className="value"/>
                            </div>
                        </div>
                    </div>
                    <div className="modalLoaderRow threePart">
                        <div className="modalLoaderCol listItem">
                            <div className="label"/>
                            <div className="input">
                                <div className="value"/>
                            </div>
                        </div>
                        <div className="modalLoaderCol smallInput">
                            <div className="label"/>
                            <div className="input">
                                <div className="value"/>
                            </div>
                        </div>
                        <div className="addBtn"/>
                    </div>
                    <div className="label"/>
                    <div className="input smallMargin">
                        <div className="value"/>
                    </div>
                    <div className="periods">
                        <div className="label"/>
                        <div className="period">
                            <div className="date"/>
                            <div className="circle"/>
                            <div className="date"/>
                        </div>
                    </div>
                    <div className="periods">
                        <div className="label"/>
                        <div className="period">
                            <div className="date"/>
                            <div className="circle"/>
                            <div className="date"/>
                        </div>
                    </div>
                    <div className="periods">
                        <div className="labelMiddle"/>
                        <div className="period">
                            <div className="date"/>
                            <div className="circle"/>
                            <div className="date"/>
                        </div>
                    </div>
                    <div className="periods">
                        <div className="label"/>
                        <div className="period">
                            <div className="date"/>
                            <div className="circle"/>
                            <div className="date"/>
                        </div>
                    </div>
                    <div className="periods">
                        <div className="labelMiddle"/>
                        <div className="period">
                            <div className="date"/>
                            <div className="circle"/>
                            <div className="date"/>
                        </div>
                    </div>
                    <div className="periods">
                        <div className="label"/>
                        <div className="period">
                            <div className="date"/>
                            <div className="circle"/>
                            <div className="date"/>
                        </div>
                    </div>
                    <div className="periods">
                        <div className="labelMiddle"/>
                        <div className="period">
                            <div className="date"/>
                            <div className="circle"/>
                            <div className="date"/>
                        </div>
                    </div>
                    <div className="avgRange">
                        <div className="labelLarge"/>
                        <div className="switch">
                            <div className="switchBtn"/>
                        </div>
                    </div>
                    <div className="label"/>
                    <div className="slider">
                        <div className="line">
                            <div className="circle" />
                        </div>
                        <div className="axis">
                            <div className="point"/>
                            <div className="point"/>
                            <div className="point"/>
                            <div className="point"/>
                            <div className="point"/>
                            <div className="point"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modalFooter">
                <div className="button"/>
                <div className="button"/>
            </div>

        </div>
    )
}

export default TradingRatesModalLoader;