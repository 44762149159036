import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {
    getProvidersAssets,
    setRateProvider
} from "@actions/providersAssetsAction";
import {FormattedMessage, injectIntl} from 'react-intl';

import {Table, Radio, Input, Tooltip} from "antd";
import {InfoCircleFilled} from '@ant-design/icons';
import {SearchOutlined} from '@ant-design/icons';
import TableLoader from "./../../../components/Loaders/tableLoader"

import {useHistory} from "react-router-dom";
import {getUrlString, getUrlValue} from "../../../utils/CommonUtils";
import {Providers, AssetProviderStatusType} from "../../../constants/Enums";

function ProvadersPage({providersAssets, intl, loading, setRateProvider, getProvidersAssets}) {

    let history = useHistory()
    const search = history && history.location && history.location.search

    const [providers, setProviders] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [limitPage, setLimit] = useState(+getUrlValue(search).limitPage || 10)
    const [currentPage, setCurrentPage] = useState(+getUrlValue(search).currentPage || 1)
    const [searchValue, setSearchValue] = useState( getUrlValue(search).searchValue || "")
    const [sortDirDesc, setSortDirDesc] = useState(  getUrlValue(search).sortDirDesc === "desc")
    const serachInputRef = useRef();
    const stor = useRef({});

    useEffect(() => {
        stor.current.searchValue =  getUrlValue(search).searchValue || "";
        stor.current.currentPage = +getUrlValue(search).currentPage || 1;
        stor.current.limitPage = +getUrlValue(search).limitPage || 10;
        stor.current.sortDirDesc = getUrlValue(search).sortDirDesc === "desc"; 
        stor.current.isPopEvent = false;
        stor.current.beforeSearchPage = null;
        stor.current.beforeSearchLimit = null;
    }, [])

    const changeAssetsProvider = (key, data) => {
        setRateProvider(data.id, key);
    }

    const columns = [
        {
            title: intl.formatMessage({id: "asset"}),
            dataIndex: 'key',
        }
    ];

    Object.keys(Providers).forEach(provider => {
        columns.push({
            title: intl.formatMessage({id: provider}),
            dataIndex: provider,
            className: 'noPadding',
            // align: "center",
            render: (text, record) => {
                const currentProvider = record.providers.filter(pr => pr.provider == Providers[provider]);
                return (
                    <Radio disabled={!currentProvider.length}
                            checked={currentProvider.length && currentProvider[0].isActive}
                            onChange={(v) => changeAssetsProvider(record.key, currentProvider[0])}
                            className={currentProvider.length? AssetProviderStatusType[currentProvider[0].status] : ''}/>
                )
            },
        },)
    })

    columns.push({
        title: ''
    })

    const pushHistpry = (currentPage, limitPage, searchValue, sortDirDesc) => {
        const {params} =  getUrlString({currentPage, limitPage, searchValue: (searchValue?.length > 0 ? searchValue : ''), sortDirDesc: sortDirDesc? "desc": ''});
        history.push({search: params.toString()});
    }

    useEffect(() => {
        if (providersAssets) {
            setProviders(providersAssets.items)
            setTotalPages(providersAssets.total)
        }
    }, [providersAssets]);

    const changeSearch = (e) => {
        stor.current.isPopEvent = false;
        setSortDirDesc(false)
        setSearchValue(e.target.value)
    }

    useEffect(() => {
        getProvidersAssets(currentPage, limitPage, searchValue, sortDirDesc);
        const {params} =  getUrlString({currentPage, limitPage,  searchValue, sortDirDesc});
        history.replace({search: params.toString()});
    }, [])

    useEffect(() => {
        if (!stor.current.isPopEvent) {

            let p = currentPage, l = limitPage, isEqual = true;

            if (searchValue.length > stor.current.searchValue.length) {
                if (searchValue.length > 0) {
                    if (!(stor.current.beforeSearchPage && stor.current.beforeSearchLimit)) {
                        stor.current.beforeSearchPage = currentPage;
                        stor.current.beforeSearchLimit = limitPage;
                    }
                    p = 1;
                }
            } else {
                if (searchValue.length < 1) {
                    p = stor.current.beforeSearchPage || currentPage;
                    l = stor.current.beforeSearchLimit || limitPage;
                    stor.current.beforeSearchPage = null;
                    stor.current.beforeSearchLimit = null;
                } else if (searchValue.length < stor.current.searchValue.length || stor.current.searchValue.length == 0 && searchValue.length != 0) {
                    p = 1;
                }
            }

            if (p != currentPage || l != limitPage) {
                isEqual = false;
            }

            setCurrentPage(+p);
            setLimit(+l);
            if (!isEqual) {
                return;
            }
            

            if (typeof stor.current.currentPage != 'undefined' && stor.current.currentPage != currentPage ||
                typeof stor.current.limitPage != 'undefined' && stor.current.limitPage != limitPage ||
                (typeof stor.current.searchValue != 'undefined' && stor.current.searchValue != searchValue && searchValue.length > 0) ||
                (typeof stor.current.sortDirDesc != 'undefined' && stor.current.sortDirDesc != sortDirDesc)) {

                pushHistpry(currentPage, limitPage, searchValue, sortDirDesc)

            } else {
                if (searchValue.length < 1 && stor.current.searchValue?.length > 0) {
                    pushHistpry(currentPage, limitPage, '', sortDirDesc)
                }
            }
        }
        
       return () => {
           stor.current.searchValue = searchValue;
           stor.current.currentPage = currentPage;
           stor.current.limitPage = limitPage;
           stor.current.sortDirDesc = sortDirDesc;
       }
    }, [searchValue, currentPage, limitPage, sortDirDesc])



    useEffect(() => {
        return history.listen((location, action) => {
            const loc = location.search;
            const {sortDirDesc, currentPage, limitPage, searchValue} = getUrlValue(loc);
            if (loc) {
                if (action === 'POP') {
                    stor.current.isPopEvent = true;
                    setSearchValue(searchValue);
                    setCurrentPage(+currentPage);
                    setLimit(+limitPage);
                    setSortDirDesc(sortDirDesc == "desc");
                    getProvidersAssets(currentPage, limitPage, searchValue, sortDirDesc == "desc");
                } else if (action === 'PUSH') {
                    getProvidersAssets(currentPage, limitPage, searchValue, sortDirDesc == "desc");
                }
            }
        })
    },[history])

    return (
        <div className="content providers">
            <div className="titleArea">
                <div className="titleArea--title">
                    <FormattedMessage id='providers'/>
                    <span className="info">
                        <Tooltip placement="bottomLeft" overlayClassName='proovider-info' title={
                            <div>
                                <p className="active"><FormattedMessage id='provider_is_actively_sending_data'/></p>
                                <p className="suspended"><FormattedMessage id='provider_has_not_sent_any_data_for_a_while_2_3_days'/></p>
                                <p className="inactive"><FormattedMessage id='provider_has_not_sent_any_data_for_a_long_time_more_than_a_month'/></p>
                            </div>
                        }>
                            <InfoCircleFilled style={{color: "#b2e3e7"}}/>
                        </Tooltip>
                    </span>
                    
                </div>
                
            </div>
            <div className="tableSearch">
                <Input suffix={<SearchOutlined/>}
                       ref={serachInputRef}
                       autoComplete="off"
                       onChange={(e) => changeSearch(e)}
                       value={searchValue}
                />
            </div>
            <div className="mainTable">
                { loading._providers_assets ? <TableLoader drag={false} icon={false} column={3} count={13} actions={0}/> :
                    <Table dataSource={providers}
                           columns={columns}
                           rowKey="key"
                           scroll={{x:'100%'}}
                           pagination={
                               {
                                   showSizeChanger: true,
                                   defaultCurrent: currentPage,
                                   defaultPageSize: limitPage,
                                   showLessItems: true,
                                   onChange: (page, limit) => {
                                    stor.current.isPopEvent = true;
                                        setLimit(l => {
                                            stor.current.isPopEvent = false;
                                            if (l === limit) {
                                                setCurrentPage(page);
                                            } else {
                                                setCurrentPage(1);
                                            }
                                            return limit;
                                        });
                                   },
                                   total: totalPages,
                                   showTotal: (total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`
                               }
                           }
                    />
                }
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        providersAssets: state.providersAssets,
        loading: state.loading,
    }
}

const mapDispatchToProps = {
    getProvidersAssets,
    setRateProvider
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProvadersPage));