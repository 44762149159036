import {
    REQUEST_LANG_LIST,
    REQUEST_TRANSLATIONS,
    REQUEST_EDIT_TRANSLATIONS,
    DELETE_TRANSLATE_ICON,
    SET_ICON_DELETED_CONDITON,
    RESET_TRANSLATION_UPDATED_STATUS,
    PUT_TRANSLATIONS_ICON
} from "@actions/actionTypes";

export function fetchLangList() {
    return dispatch => {dispatch({type: REQUEST_LANG_LIST})}
}

export function fetchTranslations(id, limit, page ) {
    return dispatch => {dispatch({type: REQUEST_TRANSLATIONS, id, limit, page})}
}

export function editTranslations(body) {
    return dispatch => {dispatch({type: REQUEST_EDIT_TRANSLATIONS, body})}
}

export function deleteTranslateIcon(icon ,key) {
    return dispatch => {dispatch({type: DELETE_TRANSLATE_ICON, icon, key})}
}

export function resetTranslationsUpdatedStatus() {
    return dispatch => {dispatch({type: RESET_TRANSLATION_UPDATED_STATUS})}
}

export function setIconDeletedCondition(key) {
    return dispatch => {dispatch({type: SET_ICON_DELETED_CONDITON, key})}
}

export function putTranslationIcon(body) {
    return dispatch => {dispatch({type: PUT_TRANSLATIONS_ICON, body})}
}