import {call, put, takeLatest, all} from 'redux-saga/effects';
import {
    REQUEST_TRADING_RATES,
    SET_TRADING_RATES,
    REQUEST_SINGLE_TRADING_RATES,
    SET_SINGLE_TRADING_RATES,
    REQUEST_EDIT_SINGLE_TRADING_RATES,
    REQUEST_IS_ENABLED_TRADING_RATE,
    SET_ERROR_MSG,
    REQUEST_ASSET_STATUS,
    SET_ASSET_STATUS,
    SET_IS_ENABLED_TRADING_RATE, SET_EDIT_SINGLE_TRADING_RATES
} from "@actions/actionTypes";
import {
    GetResource,
    PutResource,
} from '../../utils/HttpUtil';
import {onError, safe} from "./handlerError";


function* fetchTradingRates({page, limit, keyLike, sortDirDesc}) {
    const search = 2 > keyLike ? "" : encodeURIComponent(keyLike)
    const ratesGroups = yield call(GetResource, `/rate/configs?page=${page}&limit=${limit}&keyLike=${search}&sortDirDesc=${sortDirDesc}`);
    yield put({type: SET_TRADING_RATES, ratesGroups});
}

function* fetchSingleTradingRates({data}) {
    const singleTradingRate = yield call(GetResource, `/rate/configs/${data.id}/periods`);
    yield put({type: SET_SINGLE_TRADING_RATES, data: singleTradingRate});
}

function* fetchPutSingleTradingRates({data}) {
   const singleTradingRate = yield call(PutResource, `/rate/configs/${data.id}/all`, data.body);
   yield put({type: SET_EDIT_SINGLE_TRADING_RATES, data: singleTradingRate.data});
   yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}

function* putIsEnableTradingRate({data}) {
   const singleTradingRate = yield call(PutResource, `/rate/configs/${data.id}`, {isEnabled: data.isEnabled});
   yield put({type: SET_IS_ENABLED_TRADING_RATE,  data: singleTradingRate.data});
   yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}





function* getAssetStatus({id, key}) {
    const [assetGroupStatus, betCount] = yield all([
        call(GetResource, `/rate/groups/isinactive/${id}`),
        call(GetResource, `/betting/bets/count/?Key=${key}&isActive=true`)
    ])
    yield put({type: SET_ASSET_STATUS,  assetStatus: {betCount: betCount.count, assetGroupStatus: assetGroupStatus.isInActiveGroup}});
}

function* tradingRatesSaga() {
    yield takeLatest(REQUEST_TRADING_RATES, safe(onError, fetchTradingRates));
    yield takeLatest(REQUEST_SINGLE_TRADING_RATES, safe(onError, fetchSingleTradingRates));
    yield takeLatest(REQUEST_EDIT_SINGLE_TRADING_RATES, safe(onError, fetchPutSingleTradingRates));
    yield takeLatest(REQUEST_IS_ENABLED_TRADING_RATE, safe(onError, putIsEnableTradingRate));
    yield takeLatest(REQUEST_ASSET_STATUS, safe(onError, getAssetStatus));
}

export default tradingRatesSaga;