import {
    SET_ASSET_STATUS,
    SET_IS_ENABLED_TRADING_RATE,
    SET_EDIT_SINGLE_TRADING_RATES
} from "@actions/actionTypes";
import initialStore from "../../initialStore";

export default function selectedAssetStatusReducer(state = initialStore.assetsStatus, action) {
    switch (action.type) {
        case SET_ASSET_STATUS:
            return action.assetStatus
        case SET_EDIT_SINGLE_TRADING_RATES:
        case SET_IS_ENABLED_TRADING_RATE:
            return initialStore.assetsStatus
        default:
            return state;
    }
}
