import initialStore from "../../initialStore";
import {SET_RATES_GROUP_CONFIGS} from "@actions/actionTypes";

export default function ratesGroupsConfigsReducer(state = initialStore.ratesGroupConfig, action) {
    switch (action.type) {
        case SET_RATES_GROUP_CONFIGS:
            return {
                ratesGroupsConfigs : action.data.ratesGroupsConfigs,
                id: action.data.id,
                ratesGroup: action.data.ratesGroup
            }
        default:
            return state;
    }
}
