import {SET_EDIT_TRANSLATIONS, RESET_TRANSLATION_UPDATED_STATUS} from "@actions/actionTypes";

export default function  translationUpdateReducer(state = false, action) {
    switch (action.type) {
        case SET_EDIT_TRANSLATIONS:
            return true
        case RESET_TRANSLATION_UPDATED_STATUS:
            return false
        default:
            return state;
    }
}
