import RatesGroups from "../pages/contents/ratesGroups";
import TradingRates from "../pages/contents/tradingRates";
import Translations from "../pages/contents/translations";
import Settings from "../pages/contents/settings";
import Clients from "../pages/contents/clients";
import Bets from "../pages/contents/bets";
import ActiveBets from "../pages/contents/activeBets";
import Transactions from "../pages/contents/transactions";
import Providers from "../pages/contents/providers";
export default [
    {
        path: "/active_bets",
        component: ActiveBets
    },
    {
        path: "/rates_groups",
        component: RatesGroups
    },
    {
        path: "/trading_rates",
        component: TradingRates
    },
    {
        path: "/translations",
        component: Translations
    },
    {
        path: "/settings",
        component: Settings
    },
    {
        path: "/clients",
        component: Clients
    },
    {
        path: "/providers",
        component: Providers
    },
    {
        path: "/bets",
        component: Bets
    },
    {
        path: "/transactions",
        component: Transactions
    }
]