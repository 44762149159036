import {SET_MIN_MAX_DURATIONS} from "@actions/actionTypes";
import initialStore from "../../initialStore";

function minMaxDurationsReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_MIN_MAX_DURATIONS:
            return  action.minMaxDurations
        default:
            return state;
    }
}

export default minMaxDurationsReducer