import {call, takeLatest, put} from "redux-saga/effects";
import {
    REQUEST_LANG_LIST,
    REQUEST_TRANSLATIONS,
    SET_LANG_LIST,
    SET_TRANSLATIONS,
    REQUEST_EDIT_TRANSLATIONS,
    DELETE_TRANSLATE_ICON,
    SET_ICON_DELETED_CONDITON,
    SET_EDIT_TRANSLATIONS, SET_ERROR_MSG,
    PUT_TRANSLATIONS_ICON,
    REQUEST_ADD_TRANSLATIONS_ICON
} from "@actions/actionTypes";
import {
    DeleteResource,
    GetResource,
    PutResource
} from "../../utils/HttpUtil";
import {onError, safe} from "./handlerError";

let prevPage = "", prevLimit = "", prevId = "";

function* fetchLang() {
    const lang = yield call(GetResource, `/rate/translations/langs`);
    yield put({ type: SET_LANG_LIST, lang: lang.items})
}

function* fetchTranslations({ id, limit, page }) {
    page && (prevPage = page);
    limit && (prevLimit = limit);
    id && (prevId = id);
    const translations = yield call(GetResource, `/rate/translations?page=${prevPage}&limit=${prevLimit}&langid=${prevId}&foradmin=true`);
    yield put({ type: SET_TRANSLATIONS, translations})
}

function* editTranslations({ body }) {
    const translations = yield call(PutResource, `/rate/translations`, body );
    yield put({ type: SET_EDIT_TRANSLATIONS, translations: translations.data });
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}

function* deleteIcon({icon, key}) {
    yield call(DeleteResource, `/rate/files`, {url: icon} );
    yield put({type: PUT_TRANSLATIONS_ICON, body:{name: key, icon: ""}})
    yield put({type: SET_ICON_DELETED_CONDITON, key})
}

function* putTranslationIcon({body}) {
    const translations = yield call(PutResource, `/rate/translations/langkeys/${body.name}`, body );
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
    yield put({ type: REQUEST_ADD_TRANSLATIONS_ICON, translations: translations.data });
}

function* translationsSaga() {
    yield takeLatest(REQUEST_LANG_LIST, safe(onError, fetchLang));
    yield takeLatest(REQUEST_TRANSLATIONS, safe(onError, fetchTranslations));
    yield takeLatest(REQUEST_EDIT_TRANSLATIONS, safe(onError, editTranslations));
    yield takeLatest(DELETE_TRANSLATE_ICON, safe(onError, deleteIcon))
    yield takeLatest(PUT_TRANSLATIONS_ICON, safe(onError, putTranslationIcon))
}

export default translationsSaga;