import initialStore from "../../initialStore";
import { SET_LANG_LIST } from "@actions/actionTypes";

export default function langListReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_LANG_LIST:
            return  action.lang
        default:
            return state;
    }
}
