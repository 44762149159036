import {
    REQUEST_ADD_MIN_MAX_DURATIONS,
    REQUEST_MIN_MAX_DURATIONS
} from "@actions/actionTypes";

export function putMinMaxDurations(body) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_MIN_MAX_DURATIONS,
            body
        })
    }
}


export function getMinMaxDurations() {
    return dispatch => {
        dispatch({
            type: REQUEST_MIN_MAX_DURATIONS
        })
    }
}