import {sortableElement} from "react-sortable-hoc";
import DragHandle from "@components/DragAndDrope/DragHandle";
import React from "react";

const SortableItem = sortableElement(({value, height, changeConfigs}) =>
    <div className="item" id="currencySortableItem" style={{height}}>
        <DragHandle  />
        <label className="checkBox" >
            <input type="checkbox"
                   checked={value.isSelected}
                   onChange={() => changeConfigs(value)}/>
            <span className="checkmark"/>
            {value.displayName}
        </label>
    </div>);

export default SortableItem