import {SET_ICON_DELETED_CONDITON} from "@actions/actionTypes";

export default function  translationDeletedIcon(state = "", action) {
    switch (action.type) {
        case SET_ICON_DELETED_CONDITON:
            return  action.key
        default:
            return state;
    }
}
