import React from "react";

const RatesGroupsModalLoader = ({count = 15}) => {
    const elements=[];
    for(let i=0; i<count; i++){
        elements.push( <div key={i} className="item">
            <div className="drag"/>
            <div className="icon"/>
            <div className="title"/>
        </div>)
    }

    return (
            <div className="modalLoader">
                <div className="modalHeader">
                    <div className="title"/>
                    <div className="close"/>
                </div>
                <div className="modalLoaderRow">
                    <div className="modalLoaderCol mainCol leftSide">
                        <div className="label"/>
                        <div className="input">
                            <div className="value"/>
                        </div>
                        <div className="labelMiddle"/>
                        <div className="image">
                            <div className="icon"/>
                            <div className="title"/>
                            <div className="description"/>
                        </div>
                    </div>
                   <div className="modalLoaderCol mainCol rightSide">
                       <div className="labelLarge"/>
                       {elements}
                   </div>
                </div>
                <div className="modalFooter">
                     <div className="button"/>
                     <div className="button"/>
                </div>

            </div>
    )
}

export default RatesGroupsModalLoader;