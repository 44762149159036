import {getBetListFilter} from "@actions/betsActions";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Button, Form, Input, Select} from "antd";
import {GamePrediction} from "@constants/Enums";
import React, {useEffect, useState} from "react";
import {getFormattedForForm, getFormattedStateData} from "@services/betsServices";
import {getFormattedUrlObject, getUrlString, getUrlValue} from "../../utils/CommonUtils";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import predictionUp from "@assets/img/icon/up.svg";
import predictionDown from "@assets/img/icon/down.svg";
import predictionAll from "@assets/img/icon/all.svg";

const predictionIcons = [predictionAll, predictionUp, predictionDown]


function ActiveBetsFilter({filter, intl, showFilter, getBetListFilter, selectedPlayer, loading}) {
    let history = useHistory();
    const [form] = Form.useForm();
    const [clientSelect, setClientSelect] = useState([]);
    const [assetSelect, setAssetSelect] = useState([]);
    const [predictionSelect, setPredictionSelect] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);

    useEffect(() => {
        if (searchLoading && !loading._active_bet_list) {
            setSearchLoading(false)
        }
    }, [loading._active_bet_list])

    const initFormData = (loc) => {
        const urlObj = getUrlValue(loc);
        let initialForm = getFormattedUrlObject(urlObj);

        if(selectedPlayer) {
            initialForm["playerNameLike"] = selectedPlayer;
        }

        form.resetFields();
        form.setFieldsValue(getFormattedForForm(initialForm));
    }


    useEffect(() => { getBetListFilter() }, [])


    useEffect(() => {
        if (Object.keys(filter).length) {
            setClientSelect(filter.clientSelect);
            setPredictionSelect(filter.predictionSelect);
            const asset = JSON.parse(JSON.stringify(filter.assets || []));
            setAssetSelect(asset.sort(sortAssets));
        }
    }, [filter])

    useEffect(() => {
        const loc = history && history.location && history.location.search
        initFormData(loc)
    }, [])

    useEffect(() => {
        return history.listen((location) => {
            const loc = history && history.location && history.location.search
            initFormData(loc);
        })
    },[history])

    const finishFilter = (data) => {
        const {params} = getUrlString( {...getFormattedStateData(data)});
        history.push({search: params.toString()});
    }

    const resetFilter = () => {
         form.setFieldsValue({
            clientId:"",
            predictions: [],
            playerNameLike: "",
            assets: [],
            ip: "",
            stakeRangeStart: "",
            stakeRangeEnd: "",
        });
    }


    const sortAssets = (a, b) => {
        if(a.key < b.key) { return -1; }
        if(a.key > b.key) { return 1; }
        return 0;
    }

    return (
        <div className="searchBar ">
            <Form
                form={form}
                onFinish={finishFilter}
                layout="vertical"
                validateTrigger="onBlur">
                <div className={classNames("searchBar--row",{show: showFilter})}>
                    <div className="searchBar--col" id="areaClientId">
                        <Form.Item
                            label={<FormattedMessage id={"client"}/>} name="clientId">
                            <Select allowClear
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                            >
                                {clientSelect.map(client => (
                                    <Select.Option key={client.id} value={client.id}>{client.name}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="playerNameLike"
                            label={<FormattedMessage id={"player_name"}/>}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_player_name"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"bet_amount"}/>}>
                            <Form.Item name="stakeRangeStart" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[+-]?\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_from_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "from"})}/>
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="stakeRangeEnd" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[+-]?\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_to_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "to"})}/>
                            </Form.Item>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"prediction"}/>} name="predictions">
                            <Select mode="multiple" optionLabelProp="label" showArrow showSearch={false} dropdownClassName="predictionDropDown"
                                    getPopupContainer={triggerNode => triggerNode.parentElement}>
                                {predictionSelect.map(prediction => (
                                    <Select.Option key={prediction} value={""+prediction}
                                                   label={<FormattedMessage id={GamePrediction[prediction]}/>}>
                                        <img src={predictionIcons[prediction]} className="icon" alt=""/>
                                        <FormattedMessage id={GamePrediction[prediction]}/>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"assets"}/>} name="assets">
                            <Select mode="multiple" optionLabelProp="label" showArrow showSearch={true}
                                    getPopupContainer={triggerNode => triggerNode.parentElement}>
                                {assetSelect.map(asset => (
                                    <Select.Option key={asset.key} value={asset.key}
                                                   label={asset.key}>
                                        {asset.key}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"ips"}/>} name="ip" required={false}
                                   rules={[
                                       ({getFieldValue}) => ({
                                           validator(_, value) {
                                               const ipRegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g.test(value)
                                               if ((value && value.length > 1) && !ipRegExp )
                                               {
                                                   return Promise.reject(intl.formatMessage({id: `ip_is_not_valid`}))

                                               }
                                               return Promise.resolve();
                                           },
                                       })
                                   ]}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "ex._xxx.xxx.xxx.xxx"})} />
                        </Form.Item>
                    </div>
                    <div className="searchBar--col btnBox">
                        <Button type="primary"
                                htmlType="submit"
                                style={{height: 36}}
                                className="miniBtn small"
                                onClick={() => setSearchLoading(true)}
                                loading={searchLoading}
                        >
                            <FormattedMessage id='search'/>
                        </Button>
                        <button type="button" className="miniBtn default small" onClick={resetFilter}>
                            <FormattedMessage id='clear'/>
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        filter: state.betsListFilter,
        loading: state.loading
    }
}

const mapDispatchToProps = {
    getBetListFilter
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ActiveBetsFilter));